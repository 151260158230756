import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState, ThunkExtra } from "../app/store";
import { Component, componentFromPB } from "./componentDetailSlice";

interface ComponentListState {
  components: Component[] | null;
  syncing: boolean;
  connectivity: boolean;
  status: "idle" | "loading" | "failed";
}

const initialState: ComponentListState = {
  components: null,
  syncing: false,
  connectivity: true,
  status: "idle",
};

export const fetchComponentList = createAsyncThunk<Component[], { projectId: string }, ThunkExtra>(
  "componentList/fetchComponents",
  async ({ projectId }, thunkAPI) => {
    while (true) {
      try {
        const { apiClient } = thunkAPI.extra;
        const response = await apiClient.listComponents({ projectId });

        return response.components.map(componentFromPB);
      } catch (err: any) {
        return thunkAPI.rejectWithValue(err.response.data);
      }
    }
  },
);

export const componentListSlice = createSlice({
  name: "componentList",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchComponentList.fulfilled, (state, action) => {
      state.components = action.payload;
      state.status = "idle";
    });
    builder.addCase(fetchComponentList.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(fetchComponentList.rejected, (state) => {
      state.status = "failed";
    });
  },
});

export const selectComponentList = (state: RootState) => state.componentList.components;

export default componentListSlice.reducer;
