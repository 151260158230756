import {
  Alert,
  Box,
  Button,
  Grid,
  Link,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  TableContainer,
  Tooltip,
  TooltipProps,
  Typography,
  tooltipClasses,
  LinearProgress,
  Tab,
  Tabs,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import ErrorIcon from "@mui/icons-material/Error";
import { useEffect } from "react";
import { NavLink, useParams, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { GlobalError } from "../components/GlobalError";
import { OverviewBox } from "../components/OverviewBox";
import { OverviewBoxTextItem } from "../components/OverviewBoxTextItem";
import { withProjectSelector } from "../components/WithProjectBodyWrapper";
import useInterval from "../hooks/useInterval";
import {
  analyzeProposal,
  ChangeImpact,
  fetchOverview,
  postProposalPR,
  ProposalAnalysisResult,
  ProposalCombinedPackage,
  ProposalDiffPackage,
  selectConnectivity,
  selectLatestResult,
  selectPendingResult,
  selectProposal,
} from "../features/proposalDetailSlice";
import { fetchRepoList, selectRepos } from "../features/repoListSlice";
import { Proposal } from "../features/repoDetailSlice";
import { OverviewBoxItem } from "../components/OverviewBoxItem";
import { FeatureFlagGate } from "../components/FeatureFlagGate";
import { EdgeBitPrimaryButton } from "../components/EdgeBitPrimaryButton";
import FormattedTimestamp from "../components/FormattedTimestamp";
import { CodeRepo } from "../components/CodeRepo";
import { ProposalVulnerability, ProposalState } from "../pb/edgebit/platform/v1alpha/source_repos_pb";
import { PlainMessage } from "@bufbuild/protobuf";
import VulnChip from "../components/VulnChip";
import React from "react";
import { ProposalStatus } from "../components/ProposalTable";
import DifferenceIcon from "@mui/icons-material/Difference";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import { Diff, DiffFiles } from "../components/DiffDisplay";
import { AIInfo } from "../components/AIInfo";
import DoNotDisturbIcon from "@mui/icons-material/DoNotDisturb";

const MaxFailuresListed = 15;

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {value === index && (
        <Box sx={{ marginTop: "30px" }}>
          <Box>{children}</Box>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export const ProposalDetail = withProjectSelector("Proposal Details", (props: { projectId: string }) => {
  const { repoId, proposalId, tab } = useParams();
  const dispatch = useAppDispatch();
  const proposal = useAppSelector(selectProposal);
  const latestResult = useAppSelector(selectLatestResult);
  const pendingResult = useAppSelector(selectPendingResult);
  const connectivity = useAppSelector(selectConnectivity);
  const analysisState = useAppSelector((state) => state.proposalDetail.analysisState);
  const postingPr = useAppSelector((state) => state.proposalDetail.postingPr);
  const repos = useAppSelector(selectRepos);

  if (!repoId) {
    return <GlobalError message="No repository selected" />;
  }

  if (!proposalId) {
    return <GlobalError message="No proposal selected" />;
  }

  useEffect(() => {
    dispatch(fetchOverview({ projectId: props.projectId, repoId: repoId, proposalId: proposalId }));
    dispatch(fetchRepoList({ projectId: props.projectId }));
  }, [dispatch, props.projectId, repoId, proposalId]);

  useInterval(
    () => {
      if (!pendingResult) {
        return;
      }
      dispatch(fetchOverview({ projectId: props.projectId, repoId: repoId, proposalId: proposalId }));
    },
    pendingResult ? 60000 : null,
  );

  // Tab bar
  const tabContents: Record<string, { label: string; tabIndex: number; featureFlag: string | undefined }> = {
    diff: {
      label: "Code Diff Analysis",
      tabIndex: 0,
      featureFlag: "codeanalysis-diff-summary",
    },
    reachability: {
      label: "Reachability Analysis",
      tabIndex: 1,
      featureFlag: undefined,
    },
    statistics: {
      label: "Statistics",
      tabIndex: 2,
      featureFlag: "worker-job-debug",
    },
  };

  // Navigate to tab specified in URL parameter
  // If unset, tabgroup goes to 0 index
  const navigate = useNavigate();
  var currentTabIndex = 1;
  if (tab !== undefined) {
    currentTabIndex = tabContents[tab].tabIndex;
  }
  useEffect(() => {
    if (tab !== undefined) {
      navigate("/repos/" + repoId + "/proposals/" + proposalId + "/" + tab, { replace: true });
    }
  }, [repoId, proposalId, tab, navigate]);

  const handleTabChange = (event: React.SyntheticEvent | undefined, newValue: number) => {
    const selectedTabKey = Object.entries(tabContents)[newValue]?.[0];
    navigate("/repos/" + repoId + "/proposals/" + proposalId + "/" + selectedTabKey.toLowerCase());
  };

  return (
    <>
      {!connectivity && <GlobalError message="Error communicating with backend" />}
      <Typography variant="h4" gutterBottom>
        Proposals / {proposalName(proposal)}
      </Typography>

      {proposal && proposal.result?.case === "prResult" ? (
        <EdgeBitPrimaryButton
          type="submit"
          variant="outlined"
          size="medium"
          sx={{ marginTop: "20px", marginBottom: "0px", marginRight: "20px" }}
          href={proposal.result.value.url}
        >
          View Pull Request #{proposal.result.value.id}
        </EdgeBitPrimaryButton>
      ) : (
        <EdgeBitPrimaryButton
          type="submit"
          variant="outlined"
          size="medium"
          sx={{ marginTop: "20px", marginBottom: "0px", marginRight: "20px" }}
          disabled={postingPr || !latestResult || latestResult.errorMessage.length > 0}
          onClick={() => {
            // Button shouldn't be clickable if this is null
            if (!latestResult) {
              return;
            }

            dispatch(
              postProposalPR({
                projectId: props.projectId,
                repoId,
                proposalId,
                analysisResultId: latestResult!.id,
              }),
            );
          }}
        >
          Create Pull Request
        </EdgeBitPrimaryButton>
      )}

      <Button
        type="submit"
        variant="outlined"
        size="medium"
        sx={{ marginTop: "20px", marginBottom: "0px", marginRight: "20px" }}
        disabled={analysisState === "analyzing"}
        onClick={() => dispatch(analyzeProposal({ projectId: props.projectId, repoId, proposalId }))}
      >
        Re-Analyze Proposal
      </Button>

      <OverviewBox title="Details">
        {pendingResult && (
          <>
            <Alert
              severity="info"
              sx={{
                marginTop: "10px",
                marginBottom: "10px",
              }}
            >
              New analysis started at {""}
              <FormattedTimestamp timestamp={pendingResult.createdAt} />
              <FeatureFlagGate flag="worker-job-debug">
                <>
                  <Link
                    to={`/debug/worker-job/${pendingResult.jobId}`}
                    component={NavLink}
                    sx={{ fontSize: "14px", paddingLeft: "5px" }}
                  >
                    View Logs
                  </Link>
                  <Box>Pending Job ID:{pendingResult.jobId}</Box>
                </>
              </FeatureFlagGate>
              <LinearProgress sx={{ width: "100px", marginTop: "8px" }} />
            </Alert>
          </>
        )}
        <Grid container spacing={0}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            {latestResult?.completedAt && latestResult.errorMessage.length === 0 && (
              <>
                {latestResult.packages.length} dependency updates have been analyzed
                {latestResult?.assets?.safetyScore ? (
                  <>{" and impact to your app is rated " + latestResult?.assets?.safetyScore + ". "}</>
                ) : (
                  ". "
                )}
                {countVulnerabilities(latestResult.packages) > 0 && (
                  <Box sx={{ display: "inline-block" }}>
                    These updates fix {countVulnerabilities(latestResult.packages)} security issue
                    {countVulnerabilities(latestResult.packages) === 1 ? "" : "s"}.
                  </Box>
                )}
              </>
            )}
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <OverviewBoxItem label="Updated Dependencies" emptyMessage="Unknown" isEmpty={false}>
              <Box sx={{ marginTop: "5px" }}>
                {repos ? <CodeRepo repo={repos.find((repo) => repo.id === proposal?.repoId)} /> : "Not selected"}
              </Box>
              <Table
                sx={{
                  border: "1px solid #ccc",
                  borderRadius: "2px",
                  marginTop: "10px",
                  "& td": {
                    padding: "2px 6px",
                  },
                }}
              >
                <TableBody>
                  {!latestResult &&
                    pendingResult &&
                    proposal?.packages.map((pkg) => (
                      <TableRow key={pkg.package.packageName}>
                        <TableCell>{pkg.package.packageName}</TableCell>
                        <TableCell>{pkg.package.packageVersion} → pending...</TableCell>
                      </TableRow>
                    ))}
                  {latestResult?.packages
                    .slice()
                    .sort((a, b) => (packageInProposal(proposal, b) ? 1 : -1))
                    .map((pkg) => (
                      <TableRow key={pkg.name + pkg.updatedVersion}>
                        <TableCell
                          sx={{
                            verticalAlign: "top",
                            fontWeight: packageInProposal(proposal, pkg) ? "600" : "inherit",
                          }}
                        >
                          {pkg.name}
                        </TableCell>
                        <TableCell sx={{ fontWeight: packageInProposal(proposal, pkg) ? "600" : "inherit" }}>
                          {pkg.currentVersion.length === 0
                            ? pkg.updatedVersion + " (added)"
                            : pkg.currentVersion + " → " + pkg.updatedVersion}
                          <VulnList vulns={pkg.fixedVulnerabilities} />
                        </TableCell>
                        <TableCell sx={{ verticalAlign: "top" }}>
                          {pkg.diffUrl && (
                            <Link href={pkg.diffUrl} target="_blank" rel="noreferrer">
                              <DifferenceIcon sx={{ fontSize: "17px", verticalAlign: "bottom" }} />
                            </Link>
                          )}
                          {!pkg.upstreamTagFound && (
                            <Tooltip
                              title="EdgeBit could not find published source code that matches the version of this library"
                              placement="top"
                            >
                              <ReportProblemIcon sx={{ fontSize: "17px", verticalAlign: "bottom", color: "#ff0000" }} />
                            </Tooltip>
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </OverviewBoxItem>
            <OverviewBoxItem
              label="Behavior Status"
              emptyMessage="No behavior determination"
              isEmpty={latestResult?.assets?.overallBehavior ? false : true}
            >
              {latestResult?.assets?.overallBehavior}
              <AIInfo />
            </OverviewBoxItem>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={8}
            lg={8}
            sx={{
              textAlign: "right",
            }}
          >
            {latestResult?.assets && (
              <Link
                href={latestResult.assets.svgUrl}
                target="_blank"
                rel="noreferrer"
                sx={{
                  position: "relative",
                  display: "block",
                  width: "100%",
                  overflow: "hidden",
                  maxHeight: "400px",
                  marginBottom: "10px",
                }}
              >
                <img src={latestResult.assets.svgUrl} alt="Proposal Call Site Analysis" />
                <Box
                  sx={{
                    position: "absolute",
                    bottom: "0px",
                    background: "linear-gradient(to bottom, rgba(255,255,255,0), rgba(255,255,255,1))",
                    width: "100%",
                    height: "10px",
                  }}
                ></Box>
                <Box
                  sx={{
                    position: "absolute",
                    bottom: "10px",
                    fontWeight: "bold",
                    background: "#fff",
                    borderRadius: "5px",
                    textAlign: "center",
                    width: "80px",
                    left: "50%",
                    marginLeft: "-40px",
                  }}
                >
                  Expand
                </Box>
                <Box
                  sx={{
                    position: "absolute",
                    right: "0px",
                    top: "0px",
                    background: "linear-gradient(to right, rgba(255,255,255,0), rgba(255,255,255,1))",
                    padding: "3px",
                    width: "10px",
                    height: "100%",
                  }}
                ></Box>
              </Link>
            )}
          </Grid>
        </Grid>
        <Grid container spacing={0}>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            {latestResult && proposal && proposal.state && (
              <>
                <OverviewBoxItem label="Analysis Status" emptyMessage="Never" isEmpty={!latestResult}>
                  {proposal.state === ProposalState.ERRORED && (
                    <ErrorIcon
                      sx={{ color: "#ff0000", fontSize: "18px", verticalAlign: "bottom", marginRight: "3px" }}
                    />
                  )}
                  <ProposalStatus state={proposal?.state} />
                  {latestResult.errorMessage && <Box>{latestResult?.errorMessage}</Box>}
                  <FeatureFlagGate flag="worker-job-debug">
                    <Link
                      to={`/debug/worker-job/${latestResult.jobId}`}
                      component={NavLink}
                      sx={{ fontSize: "14px", display: "block" }}
                    >
                      View Logs
                    </Link>
                  </FeatureFlagGate>
                </OverviewBoxItem>
                <OverviewBoxTextItem label="Latest Job ID:" emptyMessage="Pending..." text={latestResult?.jobId} />
              </>
            )}
            {!latestResult && (
              <OverviewBoxTextItem label="Analysis Status" emptyMessage="Pending..." text="Pending..." />
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <OverviewBoxItem label="Last Analyzed" emptyMessage="Never" isEmpty={!latestResult}>
              <FormattedTimestamp timestamp={latestResult?.completedAt} />
            </OverviewBoxItem>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <OverviewBoxTextItem label="Proposal ID" emptyMessage="None" text={proposal?.id} />
          </Grid>
        </Grid>
      </OverviewBox>

      <Box sx={{ width: "100%" }}>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            margin: "0 -40px 0px -40px",
          }}
        >
          <Tabs
            value={currentTabIndex}
            onChange={handleTabChange}
            aria-label="Proposal Analysis Navigation"
            sx={{ padding: "0 40px" }}
          >
            {Object.entries(tabContents).map(([key, value]) =>
              value.featureFlag ? (
                <FeatureFlagGate key={key} flag={value.featureFlag}>
                  <Tab
                    label={value.label}
                    {...a11yProps(value.tabIndex)}
                    onClick={(e: any) => handleTabChange(e, value.tabIndex)}
                  />
                </FeatureFlagGate>
              ) : (
                <Tab key={key} label={value.label} {...a11yProps(value.tabIndex)} />
              ),
            )}
          </Tabs>
        </Box>
      </Box>

      <FeatureFlagGate flag="codeanalysis-diff-summary">
        <TabPanel value={currentTabIndex} index={0}>
          <OverviewBox title="Code Diff Analysis">
            <DiffDetail pkgs={latestResult?.diffAnalysis.packages} pkgIntel={latestResult?.packages || []} />
          </OverviewBox>
        </TabPanel>
      </FeatureFlagGate>

      <TabPanel value={currentTabIndex} index={1}>
        <OverviewBox title="Reachability Impact For Your App">
          Your code calls into these dependency changes
          <SymbolBehaviors impacts={latestResult?.impactDirect} />
        </OverviewBox>

        <OverviewBox title="Static Analysis For All Changes">
          Changes made that don't directly impact your app
          <SymbolBehaviors impacts={latestResult?.impactAll} />
        </OverviewBox>
      </TabPanel>

      <FeatureFlagGate flag="worker-job-debug">
        <TabPanel value={currentTabIndex} index={2}>
          {latestResult && JobDetail(latestResult)}
        </TabPanel>
      </FeatureFlagGate>
    </>
  );
});

const JobDetail = (result: ProposalAnalysisResult) => {
  let SimpleTable = (header: { left: string; right: string } | null, entries: any) => {
    return (
      <TableContainer>
        <Table aria-label="simple table">
          {header && (
            <TableHead>
              <TableRow>
                <TableCell align="left">{header.left}</TableCell>
                <TableCell align="right">{header.right}</TableCell>
              </TableRow>
            </TableHead>
          )}
          <TableBody>{entries}</TableBody>
        </Table>
      </TableContainer>
    );
  };
  let Row = (name: string, value: any, style: "plain" | "detail" | "tooltip") => {
    let rowProps = {
      "&:last-child td, &:last-child th": { border: 0 },
      ...(style === "detail" && { "&:hover": { cursor: "pointer" } }),
    };
    let cellProps = style === "tooltip" ? { padding: "2px 16px" } : { padding: "6px 16px" };

    return (
      <TableRow key={name} sx={rowProps}>
        <TableCell align="left" sx={cellProps}>
          {name}
        </TableCell>
        <TableCell align="right" sx={cellProps}>
          {value}
        </TableCell>
      </TableRow>
    );
  };

  let analysis = (
    <OverviewBox title="Analysis Metrics">
      {SimpleTable(
        { left: "Name", right: "Count" },
        Object.entries(result?.analysisMetrics?.counters || {}).map(([name, count]) => {
          return Row(name, count, "plain");
        }),
      )}
      {SimpleTable(
        { left: "Name", right: "Failures / Attempts" },
        Object.entries(result?.analysisMetrics?.attemptsFailures || {}).map(([name, records]) => {
          const FailuresTooltip = styled(({ className, ...props }: TooltipProps) => (
            <Tooltip {...props} classes={{ popper: className }} />
          ))(({ theme }) => ({
            [`& .${tooltipClasses.tooltip}`]: {
              maxWidth: "none",
              backgroundColor: theme.palette.common.white,
              color: "rgba(0, 0, 0, 0.87)",
              boxShadow: theme.shadows[1],
              fontSize: 11,
            },
          }));

          let attempts = records.attempts;
          let failures = Object.keys(records.failures).length;

          let info = failures ? (
            <>
              {SimpleTable(
                null,
                Object.entries(records.failures)
                  .sort((a, b) => b[1] - a[1])
                  .slice(0, MaxFailuresListed)
                  .map(([error, count]) => {
                    return Row(error, `${count}`, "tooltip");
                  }),
              )}
            </>
          ) : (
            <>No failures!</>
          );

          return (
            <FailuresTooltip key={name} title={info} placement="bottom-end">
              {Row(name, `${failures} / ${attempts}`, "detail")}
            </FailuresTooltip>
          );
        }),
      )}
    </OverviewBox>
  );

  let resources = (
    <OverviewBox title="Resource Usage">
      {SimpleTable(
        { left: "Description", right: "Value" },
        Object.entries(result?.resourceMetrics || {}).map(([name, value]) => {
          return Row(name, `${value}`, "plain");
        }),
      )}
    </OverviewBox>
  );

  return (
    <Grid container spacing={4}>
      <Grid item sm={12} md={6}>
        {analysis}
      </Grid>
      <Grid item sm={12} md={6}>
        {resources}
      </Grid>
    </Grid>
  );
};

const DiffDetail = (props: { pkgs: ProposalDiffPackage[] | undefined; pkgIntel: ProposalCombinedPackage[] }) => {
  return (
    <>
      <Typography variant="body1" sx={{ display: "block" }}>
        Semantic-aware Diff analysis of package changes without reachability.
      </Typography>
      {props.pkgs?.length === 0 && (
        <Typography variant="body1" sx={{ display: "block", marginTop: "10px", color: "#999" }}>
          <DoNotDisturbIcon
            sx={{ color: "#999", verticalAlign: "text-bottom", fontSize: "16px", marginRight: "5px" }}
          />
          Upstream diff analysis not available or no upstream packages could be matched.
        </Typography>
      )}
      {props.pkgs?.map((pkg: ProposalDiffPackage) => (
        <Box key={pkg.name}>
          <Box
            sx={{
              padding: "3px 10px",
              border: "1px solid #ccc",
              borderRadius: "3px",
              background: "#f5f5f5",
              fontSize: "13px",
              marginTop: "20px",
            }}
          >
            <Box sx={{ display: "inline-block" }}>Package:</Box>
            <Box sx={{ fontWeight: 500, display: "inline-block", paddingRight: "10px" }}>{pkg.name}</Box>
            <Box sx={{ display: "inline-block" }}>Base:</Box>
            <Box sx={{ fontWeight: 500, display: "inline-block" }}>{pkg.currentVersion}</Box>
            <Box sx={{ display: "inline-block", padding: "0 5px" }}>&rarr;</Box>
            <Box sx={{ display: "inline-block" }}>Updated:</Box>
            <Box sx={{ fontWeight: 500, display: "inline-block" }}>{pkg.updatedVersion}</Box>
          </Box>
          <Box sx={{ marginTop: "10px" }}>
            <OverviewBoxItem
              label="Change Summary"
              emptyMessage="No summary determination"
              isEmpty={pkg.overallBehavior ? false : true}
            >
              {pkg.overallBehavior}
              <AIInfo />
              <Box sx={{ fontSize: "12px", color: "#999", marginTop: "3px" }}>
                Changes unrelated to app code have been hidden by default.
              </Box>
            </OverviewBoxItem>
            <DiffFiles package={pkg} pkgIntel={props.pkgIntel} />
          </Box>
        </Box>
      ))}
    </>
  );
};

const SymbolBehaviors = (props: { impacts: ChangeImpact[] | undefined }) => {
  if (!props.impacts) {
    return <Box sx={{ marginTop: "10px", color: "#999" }}>Static analysis results not available yet.</Box>;
  }

  if (props.impacts.length === 0) {
    return (
      <Box sx={{ marginTop: "10px", color: "#999" }}>
        <DoNotDisturbIcon sx={{ color: "#999", verticalAlign: "text-bottom", fontSize: "16px", marginRight: "5px" }} />
        No symbols were affected.
      </Box>
    );
  }

  const behaviorColors: { [key: string]: string } = {
    added: "#4caf50",
    removed: "#f44336",
    refactored: "#2196f3",
    bug_fix: "#ff9800",
    undetermined: "#999",
    incomplete: "#999",
  };

  return (
    <>
      {props.impacts.map((symbol) => (
        <Box
          key={symbol.targetSymbol + symbol.callStack.map((call) => call.symbolName).join("")}
          sx={{
            marginTop: "20px",
            marginBottom: "10px",
            borderRadius: "3px",
            border: "1px solid",
            position: "relative",
            fontSize: "12px",
            display: "flex",
            justifyContent: "space-between",
            borderColor: behaviorColors[symbol.targetCategory],
            "&:last-child": {
              marginBottom: "0px",
            },
          }}
        >
          <Box
            sx={{
              position: "absolute",
              left: "20px",
              top: "-11px",
              border: "1px solid " + behaviorColors[symbol.targetCategory],
              borderRadius: "2px",
              background: "#fff",
              "& div": {
                display: "inline-block",
                padding: "0px 4px",
                borderRight: "1px solid " + behaviorColors[symbol.targetCategory],
              },
              "& div:last-child": {
                borderRight: "0px",
              },
            }}
          >
            <Box
              sx={{
                color: "#fff",
                padding: "0px 4px",
                display: "inline-block",
                background: behaviorColors[symbol.targetCategory],
              }}
            >
              {symbol.targetCategory}
            </Box>
            {symbol.targetSemantic && <Box>Semantic changes</Box>}
            {symbol.targetExported && <Box>Exported</Box>}
          </Box>

          <Box
            sx={{
              padding: "20px 20px 10px 20px",
            }}
          >
            {symbol.callStack.length === 0 && (
              <>
                <Box
                  sx={{
                    fontFamily: "monospace",
                    marginTop: "5px",
                    background: "#ddd",
                    padding: "2px 4px",
                    borderRadius: "2px",
                    display: "inline-block",
                    marginRight: "5px",
                    fontSize: "10px",
                  }}
                >
                  {symbol.targetSymbol}
                </Box>
                {symbol.targetPackage && symbol.targetFilename && (
                  <Link href={`${symbol.targetBranchUrl}${symbol.targetFilename}#L${symbol.targetLineStart}`}>
                    {symbol.targetPackage}/{symbol.targetFilename}:{symbol.targetLineStart}
                  </Link>
                )}
              </>
            )}
            {symbol.callStack.map((call, c) => (
              <Box
                key={call.symbolName}
                className="symbol-target"
                sx={{
                  paddingLeft: 20 * (c - 1) + "px",
                  "&:before": {
                    content: "'↳'",
                    position: "relative",
                    top: "1px",
                    paddingLeft: "10px",
                    paddingRight: "5px",
                    display: c === 0 ? "none" : "inline-block",
                  },
                }}
              >
                <Box
                  sx={{
                    fontFamily: "monospace",
                    marginTop: "5px",
                    background: "#ddd",
                    padding: "2px 4px",
                    borderRadius: "2px",
                    display: "inline-block",
                    marginRight: "5px",
                    fontSize: "10px",
                  }}
                >
                  {call.symbolName}
                </Box>
                {call.filename && (
                  <Link href={`${call.urlBase}${call.filename}#L${call.lineStart}`}>
                    {call.packageName ? (
                      <>
                        {call.packageName}/{call.filename}:{call.lineStart}
                      </>
                    ) : (
                      <>
                        {call.filename}:{call.lineStart}
                      </>
                    )}
                  </Link>
                )}
              </Box>
            ))}
            <Box sx={{ marginTop: "10px" }}>
              {symbol.targetAnalysis}
              <AIInfo />
            </Box>
          </Box>
          <Box
            sx={{
              width: "50%",
              borderWidth: "0px",
              borderStyle: "solid",
              borderLeftWidth: "1px",
              borderColor: behaviorColors[symbol.targetCategory],
            }}
          >
            <Diff diffText={symbol.targetDiff} limitHeight={true} />
          </Box>
        </Box>
      ))}
    </>
  );
};

const proposalName = (proposal: Proposal | null) => {
  if (!proposal?.packages || proposal.packages.length === 0) {
    return "New Proposal";
  }

  const packages = proposal.packages.map((pkg) => pkg.package.packageName);

  return "Update " + packages.join(", ");
};

const packageInProposal = (proposal: Proposal | null, pkg: ProposalCombinedPackage) => {
  return proposal?.packages.find((p) => p.package.packageName === pkg.name) !== undefined;
};

const VulnList = (props: { vulns: PlainMessage<ProposalVulnerability>[] }) => {
  return (
    <Box sx={{ marginTop: "3px", fontWeight: "400" }}>
      {props.vulns.length > 0 && " fixes "}
      {props.vulns.map((vuln, index) => (
        <React.Fragment key={vuln.url}>
          <Link href={vuln.url} target="_blank" rel="noreferrer">
            <VulnChip preset={vuln.severity} innerText={vuln.severity} />
          </Link>
          {index < props.vulns.length - 1 ? ", " : ""}
        </React.Fragment>
      ))}
    </Box>
  );
};

const countVulnerabilities = (packages: ProposalCombinedPackage[]): number => {
  return packages.reduce((count, pkg) => {
    if (pkg.fixedVulnerabilities) {
      return count + pkg.fixedVulnerabilities.length;
    }
    return count;
  }, 0);
};
