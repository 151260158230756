import { Typography, Link } from "@mui/material";
import GitHub from "@mui/icons-material/GitHub";
import { RepoDetails as RepoDetailsType } from "../features/repoDetailSlice";

export const SourceRepoLink = (props: { details: RepoDetailsType | undefined }) => {
  if (!props.details) {
    return <></>;
  }

  switch (props.details.case) {
    case "githubRepoDetails":
      return (
        <Typography>
          <GitHub sx={{ fontSize: "18px", verticalAlign: "bottom" }} />{" "}
          <Link
            href={`https://github.com/${props.details.value.owner}/${props.details.value.repo}`}
            target="_blank"
            sx={{ fontSize: "14px", verticalAlign: "baseline" }}
          >
            github.com/{props.details.value.owner}/{props.details.value.repo}
          </Link>
        </Typography>
      );
    default:
      return <></>;
  }
};
