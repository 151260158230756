import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Alert,
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { PartialMessage } from "@bufbuild/protobuf";
import { useNavigate } from "react-router-dom";
import { BodyWrapper } from "../components/BodyWrapper";
import { IntegrationSettings, IntegrationSettingsData } from "../components/IntegrationSettings";
import { EdgeBitPrimaryButton } from "../components/EdgeBitPrimaryButton";
import { GlobalError } from "../components/GlobalError";
import { EdgeBitPublicAPIService } from "../pb/edgebit/platform/v1alpha/platform_connectweb";
import { useClient } from "../api/client";
import { GitHubIntegrationSettings, VCSRepository } from "../pb/edgebit/platform/v1alpha/platform_pb";
import { FeatureFlagGate } from "../components/FeatureFlagGate";
import { GitHubAllRepos } from "../components/GitHubAllRepos";
import useProjectId from "../hooks/useProjectId";
import { appReflector } from "../utils/next_urls";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { selectSyncing, setSyncing, syncRepos } from "../features/repoListSlice";

export const GitHubDetail = () => {
  const dispatch = useAppDispatch();
  const syncing = useAppSelector(selectSyncing);
  const client = useClient(EdgeBitPublicAPIService);
  const [project, setCurrentProjectID] = useState<string | undefined>();
  const [connectivity, setConnectivity] = useState<boolean>(true);
  const { intgId } = useParams();
  const [settings, setSettings] = useState<IntegrationSettingsData[]>([]);
  const [formError, setFormError] = useState<string | null>(null);
  const [repos, setRepos] = useState<VCSRepository[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const projs = Array.from(settings.entries());
  const navigate = useNavigate();

  const handleSettingsChange = (data: IntegrationSettingsData, projectIndex: number) => {
    settings[projectIndex] = data;
    setSettings([...settings]);
  };

  useProjectId((projectId) => {
    setCurrentProjectID(projectId);
  });

  // The backend redirect requires a next parameter even though it's not used in this case
  const githubConfigLocation = `${appReflector()}/github/install?next=/`;

  useEffect(() => {
    const fetch = async () => {
      try {
        // TODO: AuthenticatedApp() already does listProjects, use that.
        const projects = (await client.listProjects({})).projects;

        const install = await client.getGitHubIntegration({
          integrationId: intgId,
        });

        const settings = projects.map((p) => {
          // locate the project in the install
          const s = install.settings.find((s) => s.projectId === p.id);
          return {
            id: p.id,
            name: p.name,
            enabled: s !== undefined,
          };
        });

        setSettings(settings);
      } catch (err) {
        console.log(err);
        setConnectivity(false);
      }
    };

    fetch();
  }, [client, intgId]);

  // Fetch connected repos
  useEffect(() => {
    if (project && repos.length === 0 && loading) {
      const fetch = async () => {
        try {
          const repos = (
            await client.listGitHubRepositories({
              projectId: project,
              integrationId: intgId,
            })
          ).repos;
          setRepos(repos);
          setLoading(false);
        } catch (err) {
          console.log(err);
          setConnectivity(false);
          setLoading(false);
        }
      };

      fetch();
    }
  }, [project, client, repos, loading, intgId]);

  // Save Form
  const handleSave = async () => {
    setFormError(null);

    try {
      const pbSettings = settings.reduce((prev: PartialMessage<GitHubIntegrationSettings>[], s) => {
        if (s.enabled) {
          prev.push({
            projectId: s.id,
          });
        }
        return prev;
      }, []);

      await client.updateGitHubIntegration({
        integrationId: intgId,
        settings: pbSettings,
      });

      navigate(`/admin/integrations/`);
    } catch (err) {
      console.log(err);
      setConnectivity(false);
    }
  };

  const handleSyncRepos = async () => {
    if (!project) {
      return;
    }

    await dispatch(syncRepos({ projectId: project }));
    // This feels kind of janky but works OK
    await new Promise((resolve) => setTimeout(resolve, 2000));

    dispatch(setSyncing(false));
  };

  useEffect(() => {
    document.title = "Configure GitHub Integration";
  });

  return (
    <BodyWrapper>
      {!connectivity && <GlobalError message="Error communicating with backend" />}
      <Typography variant="h4" gutterBottom>
        GitHub Settings
      </Typography>
      <Typography variant="body1" gutterBottom sx={{ marginBottom: "30px" }}>
        <FeatureFlagGate flag="code-analysis">
          <>
            AI Dependency Autofix continually determines the latest and safest set of dependencies for all of your
            repositories and their active branches.
            <br />
          </>
        </FeatureFlagGate>
        Integrate EdgeBit into your Pull Requests to display context and security warnings about new and modified
        software dependencies.
      </Typography>

      <Typography variant="h6" sx={{ marginBottom: "10px" }}>
        Enabled Projects
      </Typography>
      <TableContainer component={Paper} sx={{ border: "1px solid #ddd" }}>
        <Table size="small" aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ width: "150px" }}>Project Name</TableCell>
              <TableCell>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {projs.map(([i, proj]) => (
              <TableRow key={i} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                <TableCell sx={{ verticalAlign: "top", lineHeight: "38px" }}>{proj.name}</TableCell>
                <TableCell>
                  <IntegrationSettings data={proj} projectIndex={i} onChange={handleSettingsChange} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {formError && (
        <Alert style={{ marginTop: 11 }} severity="error">
          {formError}
        </Alert>
      )}

      <EdgeBitPrimaryButton
        type="submit"
        variant="outlined"
        size="medium"
        onClick={handleSave}
        sx={{ marginTop: "20px", marginBottom: "0px" }}
      >
        Save Settings
      </EdgeBitPrimaryButton>

      <Box sx={{ borderTop: "1px solid #ddd", marginTop: "40px", marginBottom: "30px" }}></Box>

      <Typography variant="h6" sx={{ marginBottom: "5px" }}>
        Repositories Connected to EdgeBit
      </Typography>
      <Typography variant="body2" gutterBottom sx={{ marginBottom: "15px" }}>
        Configure <a href={githubConfigLocation}>access to repositories over on GitHub</a> under "Repository access".
      </Typography>

      <EdgeBitPrimaryButton
        type="submit"
        variant="outlined"
        size="medium"
        sx={{ marginTop: "0px", marginBottom: "20px", marginRight: "20px" }}
        disabled={syncing}
        onClick={handleSyncRepos}
      >
        Sync Repos
      </EdgeBitPrimaryButton>

      <GitHubAllRepos repos={repos} loading={loading} />
    </BodyWrapper>
  );
};
