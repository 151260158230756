import { SourceRepository as SourceRepositoryPB } from "../../pb/edgebit/platform/v1alpha/platform_pb";
import { SourceRepo, sourceRepoFromPB } from "../../features/repoDetailSlice";

export function sourceRepositoryFromSourceRepoPB(sourceRepo?: SourceRepositoryPB): [string, string] {
  if (!sourceRepo) {
    return ["", ""];
  }

  switch (sourceRepo.kind.case) {
    case "sourceRepoDetails":
      return [sourceRepoFromPB(sourceRepo.kind.value).displayName, ""];
    case "repoUrl":
      return ["Repo URL", sourceRepo.kind.value];
    default:
      return ["", ""];
  }
}

export function sourceRepoToSourceRepositoryPB(
  repos: SourceRepo[],
  selected?: string,
  custom?: string,
): SourceRepositoryPB {
  if (selected) {
    const selectedRepo = repos.find((repo) => repo.displayName === selected);
    if (selectedRepo) {
      return new SourceRepositoryPB({
        kind: {
          case: "sourceRepoId",
          value: selectedRepo.id,
        },
      });
    }
  }

  if (custom) {
    return new SourceRepositoryPB({
      kind: {
        case: "repoUrl",
        value: custom,
      },
    });
  }

  return new SourceRepositoryPB();
}
