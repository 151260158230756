import MenuIcon from "@mui/icons-material/Menu";
import {
  AppBar,
  Box,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Toolbar,
  Typography,
} from "@mui/material";
import { EnhancedStore } from "@reduxjs/toolkit";
import { createContext, useContext, useEffect, useLayoutEffect, useState } from "react";
import { Provider, useSelector } from "react-redux";
import { NavLink, Route, Routes, createSearchParams, useNavigate, useSearchParams } from "react-router-dom";
import { useClient } from "./api/client";
import { useAppDispatch } from "./app/hooks";
import { makeStore } from "./app/store";
import { AuthContext, AuthContextProvider } from "./components/AuthContextProvider";
import { AuthenticatedRoute } from "./components/AuthenticatedRoute";
import { FeatureFlag } from "./components/FeatureFlag";
import { FeatureFlagGate, FeatureFlagGateProvider } from "./components/FeatureFlagGate";
import { GlobalError } from "./components/GlobalError";
import {
  Project,
  fetchProjects,
  selectCurrentProject,
  selectProjects,
  setCurrentProject,
} from "./features/projectSlice";
import { AcceptInvite } from "./pages/AcceptInvite";
import { ComponentCreate } from "./pages/ComponentCreate";
import { ComponentDetail } from "./pages/ComponentDetail";
import { ComponentIssueDetail } from "./pages/ComponentIssueDetail";
import { ComponentList } from "./pages/ComponentList";
import { GitHubDetail } from "./pages/GitHubDetail";
import { GitHubImport } from "./pages/GitHubImport";
import { GithubAppCompleteInstall } from "./pages/GithubAppCompleteInstall";
import { Home } from "./pages/Home";
import { IntegrationsAdd } from "./pages/IntegrationsAdd";
import { IntegrationsList } from "./pages/IntegrationsList";
import { JiraCompleteInstall } from "./pages/JiraCompleteInstall";
import { JiraDetail } from "./pages/JiraDetail";
import { Login } from "./pages/Login";
import { Logout } from "./pages/Logout";
import { MachineDetail } from "./pages/MachineDetail";
import { MachineList } from "./pages/MachineList";
import { OIDCCallback } from "./pages/OIDCCallback";
import { Onboarding } from "./pages/Onboarding";
import { OrgAccessTokenList } from "./pages/OrgAccessTokenList";
import { OrgUserList } from "./pages/OrgUserList";
import { ProjectDetail } from "./pages/ProjectDetail";
import { ProjectList } from "./pages/ProjectList";
import { ReportCreate } from "./pages/ReportCreate";
import { ReportOrgSummary } from "./pages/ReportOrgSummary";
import { ReportCompliance } from "./pages/ReportCompliance";
import { SBOMDetail } from "./pages/SBOMDetail";
import { SBOMList } from "./pages/SBOMList";
import { Signup } from "./pages/Signup";
import { TenantSelector } from "./pages/TenantSelector";
import { VantaCompleteInstall } from "./pages/VantaCompleteInstall";
import { VantaDetail } from "./pages/VantaDetail";
import { WorkloadDetail } from "./pages/WorkloadDetail";
import { WorkloadList } from "./pages/WorkloadList";
import { SlackCompleteInstall } from "./pages/SlackCompleteInstall";
import { SlackDetail } from "./pages/SlackDetail";
import { EdgeBitPublicAPIService } from "./pb/edgebit/platform/v1alpha/platform_connectweb";
// import { Project } from "./pb/edgebit/platform/v1alpha/platform_pb";
import { UserAccountService } from "./pb/edgebit/platform/v1alpha/user_account_connectweb";
import { DebugWorkerJob } from "./pages/DebugWorkerJob";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Search } from "./pages/Search";
import { AWSDetail } from "./pages/AWSDetail";
import { CodeAnalysisStart } from "./pages/CodeAnalysisStart";
import { RepoDetails } from "./pages/RepoDetails";
import { ProposalDetail } from "./pages/ProposalDetail";
import { ProposalList } from "./pages/ProposalList";
import { PrivateRegistryDetail } from "./pages/PrivateRegistryDetail";

export const ProjectContext = createContext<Project | null>(null);

const inventoryNav = [
  { name: "Overview", href: "/", current: true },
  { name: "Onboarding", href: "onboarding", current: false },
  { name: "Components", href: "components", current: false },
  { name: "SBOMs", href: "sboms", current: false },
  { name: "Machines", href: "machines", current: false },
  { name: "Workloads", href: "workloads", current: false },
  { name: "Search", href: "search", current: false },
];

const unreleasedPagesNav = [{ name: "Create Report", href: "reports/create", current: false }];

const codeAnalysisNav = [{ name: "Proposals", href: "proposals", current: false }];

const reportsNav = [
  { name: "Recent Issues", href: "reports/summary", current: false },
  { name: "Compliance", href: "reports/compliance", current: false },
];

const adminNav = [
  { name: "Users", href: "admin/users", current: false },
  { name: "Projects", href: "projects", current: false },
  { name: "Access Tokens", href: "access_tokens", current: false },
  { name: "Integrations", href: "admin/integrations", current: false },
];

const userNav = [
  {
    name: "Documentation",
    href: "",
    external: "https://edgebit.io/docs/0.x/",
    current: false,
  },
  { name: "Log out", href: "logout", current: false },
];

const drawerWidth = 220;

function AuthenticatedApp() {
  const apiClient = useClient(EdgeBitPublicAPIService);
  const [searchParams] = useSearchParams();

  const userClient = useClient(UserAccountService);
  const [userEmail, setUserEmail] = useState<string | null>(null);

  const navigate = useNavigate();

  const [isMobile, setIsMobile] = useState(false);
  const [mobileOpen, setMobileOpen] = useState(false);

  const [connectivity, setConnectivity] = useState<boolean>(true);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const dispatch = useAppDispatch();
  const projectList = useSelector(selectProjects);
  const currentProject = useSelector(selectCurrentProject);

  // Fetch project and user details
  useEffect(() => {
    dispatch(fetchProjects());

    userClient
      .getUserProfile({})
      .then(
        (res) => {
          if (res.email) {
            setUserEmail(res.email);
          } else {
            console.error("No email found");
          }
        },
        (err) => {
          console.error("Error fetching profile", err);
          setConnectivity(false);
        },
      )
      .catch((err) => {
        console.log(err);
        setConnectivity(false);
      });
  }, [dispatch, apiClient, userClient]);

  // Set project from URL parameter
  // Otherwise, default to first project returned
  useEffect(() => {
    const projectFromUrl = searchParams.get("project");

    // Check if requested project exists in list of projects
    if (projectFromUrl !== null && projectList) {
      const matchingProject = projectList?.find((project: Project) => project?.name.includes(projectFromUrl));
      if (matchingProject) {
        // Set to requested project
        dispatch(setCurrentProject(matchingProject));
      } else {
        // Set to first project
        dispatch(setCurrentProject(projectList[0]));
      }
    } else if (projectList) {
      // Set to first project
      dispatch(setCurrentProject(projectList[0]));
    }
  }, [searchParams, projectList, dispatch]);

  useEffect(() => {
    if (currentProject) {
      // Navigate to new location
      const updatedParams = createSearchParams(searchParams);
      updatedParams.set("project", currentProject.name);
      navigate(`?` + updatedParams.toString(), { replace: true });
    }
  }, [searchParams, navigate, currentProject]);

  // Watch window width and reconfigure navigation
  useLayoutEffect(() => {
    function updateSize() {
      if (window.innerWidth < 700) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  return (
    <FeatureFlagGateProvider>
      <ProjectContext.Provider value={currentProject}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <AppBar component="nav" position="static" sx={{ display: isMobile ? "block" : "none" }}>
            <Toolbar sx={{ backgroundColor: "#132243" }}>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{ mr: 2, color: "#fff" }}
              >
                <MenuIcon />
              </IconButton>
              <Box
                component="img"
                sx={{
                  height: 20,
                  marginTop: "0px",
                  marginBottom: "0px",
                  display: isMobile ? "block" : "none",
                }}
                src="/logo.png"
                alt="EdgeBit"
              />
            </Toolbar>
          </AppBar>
          <Box sx={{ display: "flex" }}>
            <Drawer
              sx={{
                width: drawerWidth,
                flexShrink: 0,
                "& .MuiDrawer-paper": {
                  width: drawerWidth,
                  height: "100vh",
                  boxSizing: "border-box",
                  bgcolor: "primary.dark",
                  color: "white",
                  overflowX: "hidden",
                  overflowY: "scroll",
                  scrollbarWidth: "none" /* Hide scroll in Firefox */,
                  MsOverflowStyle: "none" /* Hide scroll in Internet Explorer 10+ */,
                },
                "& .MuiDrawer-paper::-webkit-scrollbar": {
                  width: 0,
                  height: 0,
                },
                zIndex: "10",
              }}
              variant={isMobile ? "temporary" : "permanent"}
              anchor="left"
              open={mobileOpen}
              onClose={handleDrawerToggle}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
            >
              <Toolbar sx={{ display: isMobile ? "none" : "flex" }}>
                <Box
                  component="img"
                  sx={{ height: 30, marginTop: "30px", marginBottom: "20px" }}
                  src="/logo.png"
                  alt="EdgeBit"
                />
              </Toolbar>

              <Typography
                variant="h6"
                sx={{
                  marginLeft: "25px",
                  fontSize: "16px",
                  fontWeight: "700",
                  marginTop: isMobile ? "20px" : "0px",
                }}
              >
                Inventory
              </Typography>
              <List sx={{ padding: "0px" }} className="overview-nav-group">
                {inventoryNav.map((item) => (
                  <ListItem
                    key={item.name}
                    sx={{
                      padding: "0px",
                      marginLeft: "10px",
                      height: "30px",
                      fontWeight: "200",
                    }}
                  >
                    <ListItemButton
                      component={NavLink}
                      to={currentProject ? item.href + "?project=" + currentProject.name : item.href}
                      dense
                      onClick={handleDrawerToggle}
                    >
                      <ListItemText
                        primaryTypographyProps={{
                          fontSize: "16px",
                          fontWeight: "500",
                          marginLeft: "10px",
                          padding: "0px",
                        }}
                        primary={item.name}
                      />
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>

              <FeatureFlagGate flag="code-analysis">
                <>
                  <Typography
                    variant="h6"
                    sx={{
                      marginLeft: "25px",
                      marginTop: "20px",
                      fontSize: "16px",
                      fontWeight: "700",
                    }}
                  >
                    Dependency Autofix
                  </Typography>
                  <List sx={{ padding: "0px" }} className="overview-nav-group">
                    {codeAnalysisNav.map((item) => (
                      <ListItem
                        key={item.name}
                        sx={{
                          padding: "0px",
                          marginLeft: "10px",
                          height: "30px",
                          fontWeight: "200",
                        }}
                      >
                        <ListItemButton component={NavLink} to={item.href} dense onClick={handleDrawerToggle}>
                          <ListItemText
                            primaryTypographyProps={{
                              fontSize: "16px",
                              fontWeight: "500",
                              marginLeft: "10px",
                              padding: "0px",
                            }}
                            primary={item.name}
                          />
                        </ListItemButton>
                      </ListItem>
                    ))}
                  </List>
                </>
              </FeatureFlagGate>

              <FeatureFlag name="admin" hideByDefault={true}>
                <Typography
                  variant="h6"
                  sx={{
                    marginLeft: "25px",
                    marginTop: "20px",
                    fontSize: "16px",
                    fontWeight: "700",
                  }}
                >
                  Unreleased Pages
                </Typography>
                <List sx={{ padding: "0px" }} className="overview-nav-group">
                  {unreleasedPagesNav.map((item) => (
                    <ListItem
                      key={item.name}
                      sx={{
                        padding: "0px",
                        marginLeft: "10px",
                        height: "30px",
                        fontWeight: "200",
                      }}
                    >
                      <ListItemButton component={NavLink} to={item.href} dense onClick={handleDrawerToggle}>
                        <ListItemText
                          primaryTypographyProps={{
                            fontSize: "16px",
                            fontWeight: "500",
                            marginLeft: "10px",
                            padding: "0px",
                          }}
                          primary={item.name}
                        />
                      </ListItemButton>
                    </ListItem>
                  ))}
                </List>
              </FeatureFlag>

              <Typography
                variant="h6"
                sx={{
                  marginLeft: "25px",
                  marginTop: "20px",
                  fontSize: "16px",
                  fontWeight: "700",
                }}
              >
                Reports
              </Typography>
              <List sx={{ padding: "0px" }} className="overview-nav-group">
                {reportsNav.map((item) => (
                  <ListItem
                    key={item.name}
                    sx={{
                      padding: "0px",
                      marginLeft: "10px",
                      height: "30px",
                      fontWeight: "200",
                    }}
                  >
                    <ListItemButton component={NavLink} to={item.href} dense onClick={handleDrawerToggle}>
                      <ListItemText
                        primaryTypographyProps={{
                          fontSize: "16px",
                          fontWeight: "500",
                          marginLeft: "10px",
                          padding: "0px",
                        }}
                        primary={item.name}
                      />
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>

              <Typography
                variant="h6"
                sx={{
                  marginLeft: "25px",
                  marginTop: "20px",
                  fontSize: "16px",
                  fontWeight: "700",
                }}
              >
                Organization
              </Typography>
              <List sx={{ padding: "0px" }} className="overview-nav-group">
                {adminNav.map((item) => (
                  <ListItem
                    key={item.name}
                    sx={{
                      padding: "0px",
                      marginLeft: "10px",
                      height: "30px",
                      fontWeight: "200",
                    }}
                  >
                    <ListItemButton component={NavLink} to={item.href} dense onClick={handleDrawerToggle}>
                      <ListItemText
                        primaryTypographyProps={{
                          fontSize: "16px",
                          fontWeight: "500",
                          marginLeft: "10px",
                          padding: "0px",
                        }}
                        primary={item.name}
                      />
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>

              <Typography
                variant="h6"
                sx={{
                  marginLeft: "25px",
                  marginTop: "20px",
                  fontSize: "16px",
                  fontWeight: "700",
                  maxWidth: "165px",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                }}
              >
                <Box
                  component="span"
                  sx={{
                    display: "inline-block",
                    maxWidth: "100%",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                  }}
                >
                  {userEmail}
                </Box>
              </Typography>
              <List sx={{ padding: "0px" }} className="overview-nav-group">
                {userNav.map((item) =>
                  item.external ? (
                    <a
                      key={item.name}
                      className="external-nav"
                      href={item.external}
                      target="_blank"
                      rel="noreferrer"
                      style={{
                        color: "#fff",
                        fontSize: "16px",
                        fontWeight: "500",
                        marginLeft: "35px",
                        padding: "0px",
                      }}
                    >
                      {item.name}
                    </a>
                  ) : (
                    <ListItem
                      key={item.name}
                      sx={{
                        padding: "0px",
                        marginLeft: "10px",
                        height: "30px",
                        fontWeight: "200",
                      }}
                    >
                      <ListItemButton component={NavLink} to={item.href} dense onClick={handleDrawerToggle}>
                        <ListItemText
                          primaryTypographyProps={{
                            fontSize: "16px",
                            fontWeight: "500",
                            marginLeft: "10px",
                            padding: "0px",
                          }}
                          primary={item.name}
                        />
                      </ListItemButton>
                    </ListItem>
                  ),
                )}
              </List>
            </Drawer>
            <Routes>
              <Route index element={<Home />} />
              <Route path="login" element={<Login />} />
              <Route path="logout" element={<Logout />} />
              <Route path="signup" element={<Signup />} />
              <Route path="tenant" element={<TenantSelector />} />
              <Route path="access_tokens" element={<OrgAccessTokenList />} />
              <Route path="admin/users" element={<OrgUserList />} />
              <Route path="admin/integrations" element={<IntegrationsList />} />
              <Route path="admin/integrations/add" element={<IntegrationsAdd />} />
              <Route path="admin/github/:intgId" element={<GitHubDetail />} />
              <Route path="admin/jira/:intgId" element={<JiraDetail />} />
              <Route path="admin/vanta/:intgId" element={<VantaDetail />} />
              <Route path="admin/slack/:intgId" element={<SlackDetail />} />
              <Route path="admin/aws/:intgId" element={<AWSDetail />} />
              <Route path="admin/registries/:intgId" element={<PrivateRegistryDetail />} />
              <Route path="projects" element={<ProjectList />} />
              <Route path="projects/:projectId" element={<ProjectDetail />} />
              <Route path="onboarding" element={<Onboarding />} />
              <Route path="machines" element={<MachineList />} />
              <Route path="machines/:machineId" element={<MachineDetail />} />
              <Route path="sboms" element={<SBOMList />} />
              <Route path="sboms/:sbomId" element={<SBOMDetail />} />
              <Route path="components" element={<ComponentList />} />
              <Route path="components/create" element={<ComponentCreate />} />
              <Route path="components/github-import" element={<GitHubImport />} />
              <Route path="components/:componentId/:tab?" element={<ComponentDetail />} />
              <Route path="components/:componentId/issues/:issueId" element={<ComponentIssueDetail />} />
              <Route path="repos/:repoId" element={<RepoDetails />} />
              <Route path="repos/:repoId/proposals/:proposalId/:tab?" element={<ProposalDetail />} />
              <Route path="reports/create" element={<ReportCreate />} />
              <Route path="reports/summary" element={<ReportOrgSummary />} />
              <Route path="reports/compliance" element={<ReportCompliance />} />
              <Route path="workloads" element={<WorkloadList />} />
              <Route path="workloads/:workloadId" element={<WorkloadDetail />} />
              <Route path="github-complete-install/:type" element={<GithubAppCompleteInstall />} />
              <Route path="jira-complete-install" element={<JiraCompleteInstall />} />
              <Route path="vanta-complete-install" element={<VantaCompleteInstall />} />
              <Route path="slack-complete-install" element={<SlackCompleteInstall />} />
              <Route path="codeanalysis/start" element={<CodeAnalysisStart />} />
              <Route path="debug/worker-job/:jobId" element={<DebugWorkerJob />} />
              <Route path="search" element={<Search />} />
              <Route path="proposals" element={<ProposalList />} />
            </Routes>
          </Box>
          {!connectivity && <GlobalError message="Error communicating with backend" />}
        </LocalizationProvider>
      </ProjectContext.Provider>
    </FeatureFlagGateProvider>
  );
}

interface StoreState {}

function StoreProvider(props: { children?: any }) {
  const { transport } = useContext(AuthContext);
  const [store, setStore] = useState<EnhancedStore<StoreState> | null>(null);

  useEffect(() => {
    if (transport) {
      const newStore = makeStore(transport);
      setStore(newStore);
    }
  }, [transport]);

  if (!store) {
    return <div>Loading...</div>;
  }

  return <Provider store={store}>{props.children}</Provider>;
}

export default function App() {
  if (window.location.hostname.startsWith("signup.")) {
    return <Signup />;
  }
  if (window.location.hostname.startsWith("accounts.")) {
    return <TenantSelector />;
  }

  return (
    <AuthContextProvider>
      <Routes>
        <Route path="login" element={<Login />} />
        <Route path="login/oidc_callback" element={<OIDCCallback />} />
        <Route path="accept_invite" element={<AcceptInvite />} />
        <Route
          path="*"
          element={
            <AuthenticatedRoute>
              <StoreProvider>
                <AuthenticatedApp />
              </StoreProvider>
            </AuthenticatedRoute>
          }
        />
      </Routes>
    </AuthContextProvider>
  );
}
