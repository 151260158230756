import { Button, Grid, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { GlobalError } from "../components/GlobalError";
import { OverviewBox } from "../components/OverviewBox";
import { OverviewBoxItem } from "../components/OverviewBoxItem";
import { OverviewBoxTextItem } from "../components/OverviewBoxTextItem";
import { withProjectSelector } from "../components/WithProjectBodyWrapper";
import { scanRepo, selectConnectivity, selectPackages, selectRepo, selectScanning } from "../features/repoDetailSlice";
import { CodeRepo } from "../components/CodeRepo";
import { RepoPackageTable } from "../components/RepoPackageTable";
import { SourceRepoLink } from "../components/SourceRepoLink";

export const RepoDetails = withProjectSelector("Repository Details", (props: { projectId: string }) => {
  const { repoId } = useParams();
  const dispatch = useAppDispatch();
  const connectivity = useAppSelector(selectConnectivity);
  const repo = useAppSelector(selectRepo);
  const packages = useAppSelector(selectPackages);
  const scanning = useAppSelector(selectScanning);

  if (!repoId) {
    return <GlobalError message="No repository selected" />;
  }

  const triggerScan = () => {
    dispatch(scanRepo({ projectId: props.projectId, repoId: repoId, componentId: "" }));
  };

  return (
    <>
      {!connectivity && <GlobalError message="Error communicating with backend" />}
      <Typography variant="h4" gutterBottom>
        Repositories / {repo && repo.displayName}
      </Typography>

      <Button
        type="submit"
        variant="outlined"
        size="medium"
        disabled={scanning}
        sx={{ marginTop: "20px", marginBottom: "0px", marginRight: "20px" }}
        onClick={triggerScan}
      >
        Re-Scan Repo
      </Button>

      <OverviewBox title="Source Repository">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <OverviewBoxItem label="Repository Name" isEmpty={!repo} emptyMessage="None">
              <CodeRepo repo={repo ? repo : undefined} />
            </OverviewBoxItem>
            <OverviewBoxTextItem label="Default Branch" emptyMessage="None" text={repo?.targetBranch} />
            <OverviewBoxItem label="Link" emptyMessage="None" isEmpty={repo?.details.case === undefined}>
              <SourceRepoLink details={repo?.details} />
            </OverviewBoxItem>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <OverviewBoxTextItem label="Repository ID" emptyMessage="None" text={repo?.id} />
            <OverviewBoxTextItem
              label="Repository Type"
              emptyMessage="None"
              text={
                repo && repo.details.case
                  ? {
                      githubRepoDetails: "GitHub",
                    }[repo.details.case]
                  : undefined
              }
            />
          </Grid>
        </Grid>
      </OverviewBox>
      <Typography variant="h6" sx={{ marginBottom: "10px" }}>
        Package Inventory
      </Typography>
      {packages && repo && <RepoPackageTable repo={repo} packages={packages} />}
    </>
  );
});
