import {
  Alert,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
  Link,
} from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { CodeRepo } from "./CodeRepo";
import { EdgeBitPrimaryButton } from "./EdgeBitPrimaryButton";
import {
  selectProjectID,
  selectTargetPkg,
  setProjectID,
  setSearch,
  setTargetCategory,
  selectOpenPullRequest,
  setOpenPullRequest,
  closeSpecificPackageModal,
  selectSpecificPackageModalOpen,
  fetchProposals,
  createProposal,
  selectCreatingProposal,
  selectTargetCategory,
  selectTargetComponent,
  TargetCategory,
  setTargetOptions,
  TargetOptions,
  selectTargetOptions,
} from "../features/proposalListSlice";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { selectRepos, selectNewSourceRepoPackage, setNewSourceRepoPackage } from "../features/repoListSlice";
import useProjectId from "../hooks/useProjectId";
import { useNavigate, NavLink } from "react-router-dom";
import { useCallback, useEffect } from "react";
import { SourceRepoPackage } from "../features/repoDetailSlice";

interface DialogTriggerPackageAnalysisProps {}

export function DialogTriggerPackageAnalysis(props: DialogTriggerPackageAnalysisProps) {
  const dispatch = useAppDispatch();
  const specificPackageModalOpen = useAppSelector(selectSpecificPackageModalOpen);
  const targetPkg = useAppSelector(selectTargetPkg);
  const openPullRequest = useAppSelector(selectOpenPullRequest);
  const repos = useAppSelector(selectRepos);
  const projectId = useAppSelector(selectProjectID);
  const component = useAppSelector(selectTargetComponent);
  const category = useAppSelector(selectTargetCategory);
  const options = useAppSelector(selectTargetOptions);
  const newPkg = useAppSelector(selectNewSourceRepoPackage);
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(setTargetOptions({ subDirectory: component?.subdir, legacyPeerDeps: true }));
  }, [dispatch, component]);

  const handlePullRequest = () => {
    dispatch(setOpenPullRequest(!openPullRequest));
  };

  const handleSpecificPackageModalClose = () => {
    dispatch(closeSpecificPackageModal());
  };

  const handleStartPackageAnalysis = async () => {
    dispatch(setSearch(""));
    if (targetPkg && category) {
      let forceVersions: { [key: string]: string } = {};
      if (newPkg) {
        forceVersions = { [targetPkg.id]: newPkg.packageVersion };
      }
      await dispatch(
        createProposal({
          projectId: projectId,
          repoId: targetPkg.repoId,
          packageIds: [targetPkg.id],
          autoPropose: openPullRequest,
          category: category,
          options: options,
          forceVersions: forceVersions,
        }),
      );
    }
    dispatch(closeSpecificPackageModal());
    navigate("/proposals");
    dispatch(fetchProposals({ projectId: projectId }));
  };

  const handleTargetCategory = useCallback(
    (updatedProps: TargetCategory) => {
      let mergedProps = { ...category, ...updatedProps };
      dispatch(setTargetCategory(mergedProps));
    },
    [category, dispatch],
  );

  const handleTargetOptions = useCallback(
    (updatedProps: TargetOptions) => {
      let mergedProps = { ...options, ...updatedProps };
      dispatch(setTargetOptions(mergedProps));
    },
    [options, dispatch],
  );

  const handleBranchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleTargetCategory({ branch: event.target.value });
  };

  const handleForceVersionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (targetPkg) {
      // set ID to empty string to indicate creation of a new package
      let target: SourceRepoPackage = { ...targetPkg, packageVersion: event.target.value, id: "" };
      dispatch(setNewSourceRepoPackage(target));
    }
  };

  const handlePeerDepChange = (event: SelectChangeEvent<any>) => {
    handleTargetOptions({ legacyPeerDeps: event.target.value === "relaxed" });
  };

  const handleSubDirectoryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleTargetOptions({ subDirectory: event.target.value });
  };

  useProjectId((projectId: string) => {
    dispatch(setProjectID(projectId));
  });

  return (
    <Dialog
      open={specificPackageModalOpen}
      onClose={handleSpecificPackageModalClose}
      aria-labelledby="alert-dialog-title"
    >
      <DialogTitle id="alert-dialog-title">Start Upgrade Analysis for {targetPkg?.packageName}</DialogTitle>
      {!targetPkg && specificPackageModalOpen && (
        <Alert style={{ marginTop: 11 }} severity="error">
          Select a package to analyze before continuing.
        </Alert>
      )}
      <DialogContent>
        <DialogContentText>
          A proposal will be created to analyze the upgrade for {targetPkg?.packageName} plus any required dependencies.
        </DialogContentText>

        <Grid sx={{ marginTop: "10px" }}>
          <Grid container spacing={2}>
            <Grid item xs={6} sm={4} md={4} lg={4} sx={{ fontWeight: "600" }}>
              <Box>Component:</Box>
            </Grid>
            <Grid item xs={8} sm={8} md={6} lg={5}>
              {component ? (
                <>
                  <Link component={NavLink} to={`/components/${component.id}`}>
                    {component.displayName}
                  </Link>
                </>
              ) : (
                "Not selected"
              )}
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={6} sm={4} md={4} lg={4} sx={{ fontWeight: "600", marginTop: "5px" }}>
              <Box>Repository:</Box>
            </Grid>
            <Grid item xs={8} sm={8} md={8} lg={8} sx={{ marginTop: "5px" }}>
              {targetPkg && repos ? (
                <CodeRepo repo={repos.find((repo) => repo.id === targetPkg.repoId)} />
              ) : (
                "Not selected"
              )}
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={6} sm={4} md={4} lg={4} sx={{ fontWeight: "600", lineHeight: "40px", marginTop: "10px" }}>
              <Box>Branch:</Box>
            </Grid>
            <Grid item xs={8} sm={8} md={6} lg={5}>
              <FormControl
                sx={{
                  width: "100%",
                  maxWidth: 150,
                  backgroundColor: "#fff",
                  textAlign: "left",
                  margin: "10px 0",
                }}
              >
                <TextField
                  required
                  label=""
                  value={category?.branch || repos?.find((repo) => repo.id === targetPkg?.repoId)?.targetBranch || ""}
                  onChange={handleBranchChange}
                  size="small"
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={6} sm={4} md={4} lg={4} sx={{ fontWeight: "600" }}>
              <Box>Package:</Box>
            </Grid>
            <Grid item xs={8} sm={8} md={6} lg={5}>
              {targetPkg ? targetPkg.packageName : "Not selected"}
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={6} sm={4} md={4} lg={4} sx={{ fontWeight: "600" }}>
              <Box>Current Version:</Box>
            </Grid>
            <Grid item xs={8} sm={8} md={6} lg={5}>
              {targetPkg?.id !== "" ? targetPkg?.packageVersion : "Not selected"}
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={6} sm={4} md={4} lg={4} sx={{ fontWeight: "600" }}>
              <Box>Target Version:</Box>
            </Grid>
            <Grid item xs={8} sm={8} md={6} lg={5}>
              {newPkg ? newPkg?.packageVersion : "Determined by update graph"}
            </Grid>
          </Grid>
        </Grid>

        <Box sx={{ marginTop: "10px" }}>
          <Accordion sx={{ boxShadow: "0", padding: "0", minHeight: "unset" }}>
            <AccordionSummary
              expandIcon={<ArrowDownwardIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
              sx={{ padding: "0", color: "#6096FF", "& div": { margin: "0" } }}
            >
              Advanced Options
            </AccordionSummary>
            <AccordionDetails sx={{ padding: "0" }}>
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={6}
                  sm={4}
                  md={4}
                  lg={4}
                  sx={{ fontWeight: "600", lineHeight: "40px", marginTop: "10px" }}
                >
                  <Box>Force Version:</Box>
                </Grid>
                <Grid item xs={8} sm={8} md={6} lg={5}>
                  <FormControl
                    sx={{
                      width: "100%",
                      maxWidth: 150,
                      backgroundColor: "#fff",
                      textAlign: "left",
                      margin: "10px 0",
                    }}
                  >
                    <TextField
                      label=""
                      value={newPkg?.packageVersion ? newPkg?.packageVersion : ""}
                      placeholder="1.2.3"
                      onChange={handleForceVersionChange}
                      size="small"
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={6}
                  sm={4}
                  md={4}
                  lg={4}
                  sx={{ fontWeight: "600", lineHeight: "40px", marginTop: "10px" }}
                >
                  <Box>Subdirectory Path:</Box>
                </Grid>
                <Grid item xs={8} sm={8} md={6} lg={5}>
                  <FormControl
                    sx={{
                      width: "100%",
                      maxWidth: 150,
                      backgroundColor: "#fff",
                      textAlign: "left",
                      margin: "10px 0",
                    }}
                  >
                    <TextField
                      label=""
                      value={options?.subDirectory ?? ""}
                      placeholder=""
                      onChange={handleSubDirectoryChange}
                      size="small"
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={6}
                  sm={4}
                  md={4}
                  lg={4}
                  sx={{ fontWeight: "600", lineHeight: "40px", marginTop: "10px" }}
                >
                  <Box>Peer Dependencies:</Box>
                </Grid>
                <Grid item xs={8} sm={8} md={6} lg={5}>
                  <FormControl
                    sx={{
                      width: "100%",
                      maxWidth: 150,
                      backgroundColor: "#fff",
                      textAlign: "left",
                      margin: "10px 0",
                    }}
                  >
                    <Select
                      name="repo-name"
                      displayEmpty
                      size="small"
                      onChange={handlePeerDepChange}
                      value={options?.legacyPeerDeps ? "relaxed" : "null"}
                    >
                      <MenuItem value="null">Default</MenuItem>
                      <MenuItem value="relaxed">Relaxed</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Box>

        <Typography
          sx={{
            fontWeight: 600,
            fontSize: "16px",
            borderTop: "1px solid #ddd",
            marginTop: "20px",
            paddingTop: "18px",
            marginBottom: "0px",
          }}
        >
          Proposal Outputs
        </Typography>
        <FormGroup sx={{ verticalAlign: "top" }}>
          <FormControlLabel
            control={<Checkbox size="small" checked={true} disabled={true} />}
            label={"Detailed Analysis & Impact Report"}
          />
        </FormGroup>
        <FormGroup sx={{ verticalAlign: "top" }}>
          <FormControlLabel
            control={<Checkbox size="small" checked={openPullRequest} onChange={() => handlePullRequest()} />}
            label="Create Pull Request"
          />
        </FormGroup>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "flex-start", borderTop: "1px solid #ddd", padding: "10px 10px 10px 23px" }}>
        <EdgeBitPrimaryButton onClick={handleStartPackageAnalysis} disabled={useAppSelector(selectCreatingProposal)}>
          Start Analysis
        </EdgeBitPrimaryButton>
        <Button tabIndex={-1} onClick={handleSpecificPackageModalClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
