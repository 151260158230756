import { Alert, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useClient } from "../api/client";
import { BodyWrapperProjectScoped } from "../components/BodyWrapperProjectScoped";
import { GlobalError } from "../components/GlobalError";
import { WorkerJobDebugService } from "../pb/edgebit/platform/v1alpha/worker_job_debug_connectweb";

type LogEvent = {
  rawLine: string;
  timestamp: string;
  level: LogLevel;
  message: string;
  caller: string;
  logger: string | null;
  step: {
    id: number;
    name: string;
  } | null;
  extra: { [key: string]: any };
};

// Log lines get parsed into one of these to get types assigned to a subset of fields.
type ParsedLogEvent = {
  ts: number;
  level: LogLevel;
  logger: string | null;
  [key: string]: any;
};

const createLogEvent = (rawLine: string): LogEvent => {
  const parsed: ParsedLogEvent = JSON.parse(rawLine);

  // Destructure the parsed object into known properties and rest (`extra`).
  const { ts, level, msg, caller, logger, ...extra } = parsed;

  // Logger is of the form "steps.<step_id>" (legacy) or "steps.<step_id>.<step_name>"
  const stepMatch = logger?.match(/^steps\.([^.]+)(\.([^.]+))?/);
  let step = null;
  if (stepMatch) {
    let id = parseInt(stepMatch[1]);
    if (stepMatch[3]) {
      let name = stepMatch[3];
      step = { id, name };
    } else {
      let name = "Step " + id;
      step = { id, name };
    }
  }

  return {
    rawLine,
    timestamp: new Date(ts * 1000).toISOString(),
    level,
    message: msg,
    caller,
    logger,
    step,
    extra,
  };
};

type LogLevel = "debug" | "info" | "warn" | "error" | "panic" | "fatal";

interface SuccessResult {}

interface ErrorResult {
  message: string;
}

type Result = { case: "success"; value: SuccessResult } | { case: "error"; value: ErrorResult };

type displayMode = "pretty" | "raw";
type groupMode = "step" | "all";

export const DebugWorkerJob = () => {
  const { jobId } = useParams();
  const client = useClient(WorkerJobDebugService);

  const [logDisplayMode, setLogDisplayMode] = useState<displayMode>("pretty");
  const [logGroupMode, setLogGroupMode] = useState<groupMode>("step");

  const [result, setResult] = useState<Result | null>(null);
  const [logs, setLogs] = useState<LogEvent[]>([]);
  const [logsByStep, setLogsByStep] = useState<{ [step: number]: { name: string; events: LogEvent[] } }>({});
  const [loadError, setLoadError] = useState<string | null>(null);

  useEffect(() => {
    if (!jobId) {
      return;
    }

    setResult(null);
    setLogs([]);
    setLogsByStep({});

    async function stream() {
      for await (const res of client.streamJobLogs({ jobId: jobId })) {
        switch (res.response.case) {
          case "logLine":
            const event = createLogEvent(res.response.value.rawLine);
            setLogs((logs) => [...logs, event]);
            if (event.step != null) {
              const step = event.step;
              setLogsByStep((logsByStep) => {
                const currentLogs = logsByStep[step.id] || { name: step.name, events: [] };
                return {
                  ...logsByStep,
                  [step.id]: {
                    name: step.name,
                    events: [...currentLogs.events, event],
                  },
                };
              });
            }
            break;

          case "result":
            const result = res.response.value.result;
            switch (result.case) {
              case "success":
                setResult({ case: "success", value: {} });
                break;

              case "failure":
                setResult({ case: "error", value: { message: result.value.message } });
                break;
            }
        }
      }
    }

    stream().catch((err) => {
      setLoadError(err.message);
    });
  }, [client, jobId]);

  useEffect(() => {
    document.title = "Job " + jobId?.split("-")[1] + " Logs - EdgeBit";
  }, [jobId]);

  return (
    <BodyWrapperProjectScoped>
      {loadError && <GlobalError message={loadError} fixed={true} />}
      <Typography variant="h4" gutterBottom>
        Worker Jobs / {jobId}
      </Typography>

      {result && <ResultDisplay result={result} />}

      <Box>
        <Typography variant="h5">Job Logs</Typography>
        <ToggleButtonGroup
          size="small"
          color="primary"
          value={logDisplayMode}
          exclusive
          onChange={(_, value) => setLogDisplayMode(value)}
          sx={{ marginBottom: "10px", marginRight: "20px" }}
        >
          <ToggleButton value="pretty">Formatted Display</ToggleButton>
          <ToggleButton value="raw">Raw Display</ToggleButton>
        </ToggleButtonGroup>

        <ToggleButtonGroup
          size="small"
          color="primary"
          value={logGroupMode}
          exclusive
          onChange={(_, value) => setLogGroupMode(value)}
          sx={{ marginBottom: "10px", marginRight: "20px" }}
        >
          <ToggleButton value="step">Group by Step</ToggleButton>
          <ToggleButton value="all">Combined</ToggleButton>
        </ToggleButtonGroup>

        {logGroupMode === "all" ? (
          <LogSection title="All Logs" events={logs} displayMode={logDisplayMode} />
        ) : (
          <>
            <LogSection
              title="Worker Logs (excluding step logs)"
              events={logs.filter((e) => e.step === null)}
              displayMode={logDisplayMode}
            />
            {Object.entries(logsByStep).map(([id, step], i) => (
              <LogSection key={id} title={step.name} events={step.events} displayMode={logDisplayMode} />
            ))}
          </>
        )}
      </Box>
    </BodyWrapperProjectScoped>
  );
};

const ResultDisplay = (props: { result: Result }) => {
  if (props.result.case === "success") {
    return <Alert severity="success">Job completed successfully</Alert>;
  } else {
    return <Alert severity="error">Job failed: {props.result.value.message}</Alert>;
  }
};

const LogSection = (props: { title: string; events: LogEvent[]; displayMode: displayMode }) => {
  const [maxLines, setMaxLines] = useState<number | undefined>(6);

  const expandLog = () => {
    setMaxLines(undefined);
  };

  // find first log after "step prepared" if it exists
  // this is the start of the actual processing for containerized steps
  let firstLine: string | undefined = props.events
    .slice(props.events.findIndex((e) => e.message.includes("step prepared")) + 1)
    .map((e) => e.timestamp)[0];
  const lastLine: string | undefined = props.events.at(-1)?.timestamp;

  return (
    <>
      <Typography variant="h6" sx={{ display: "inline-block", marginRight: "15px" }}>
        {props.title}
      </Typography>
      {firstLine && lastLine && (
        <Typography variant="body2" color="textSecondary" sx={{ display: "inline-block" }}>
          {logDuration(lastLine, firstLine)}
        </Typography>
      )}

      <Box
        fontFamily="monospace"
        bgcolor={"#333"}
        marginBottom={2}
        marginTop={1}
        sx={{
          whiteSpace: "pre-wrap",
          padding: "6px 10px",
          fontSize: "12px",
          minHeight: "50px",
          position: "relative",
        }}
      >
        {props.events.slice(0, maxLines || props.events.length).map((event, i) => (
          <LogRow key={i} event={event} displayMode={props.displayMode} />
        ))}
        {props.events.length === 0 && <Box sx={{ fontStyle: "italic", color: "#fff" }}>Awaiting logs...</Box>}
        {maxLines && props.events.length > maxLines && (
          <Box
            sx={{
              position: "absolute",
              bottom: 0,
              left: "50%",
              padding: "6px 10px",
              fontSize: "12px",
              cursor: "pointer",
              userSelect: "none",
              color: "#f8f8f2",
              backgroundColor: "#6096FF",
              width: "100px",
              marginLeft: "-50px",
            }}
            onClick={expandLog}
          >
            Expand logs
          </Box>
        )}
      </Box>
    </>
  );
};

const LogLevelText = (props: { level: LogLevel }) => {
  const colors: { [key in LogLevel]: string } = {
    debug: "gray",
    info: "green",
    warn: "orange",
    error: "red",
    panic: "purple",
    fatal: "black",
  };

  return <span style={{ color: colors[props.level] }}>[{props.level.toUpperCase()}]</span>;
};

const LogRow = (props: { event: LogEvent; displayMode: displayMode }) => {
  const { event } = props;
  return (
    <Box color={"#f8f8f2"}>
      {props.displayMode === "raw" ? (
        <>{event.rawLine}</>
      ) : (
        <>
          <Box sx={{ userSelect: "none", display: "inline-block" }}>{event.timestamp}</Box>{" "}
          <LogLevelText level={event.level} /> {event.message}
          {Object.keys(event.extra).length > 0 && " " + JSON.stringify(event.extra)}
        </>
      )}
    </Box>
  );
};

function logDuration(end: string, start: string): string {
  let difference = new Date(end).getTime() - new Date(start).getTime();

  let hours = Math.floor(difference / 1000 / 60 / 60);
  let minutes = Math.floor(difference / 1000 / 60) % 60;
  let seconds = Math.floor(difference / 1000) % 60;

  return hours + "h " + minutes + "m " + seconds + "s";
}
