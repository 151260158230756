import { Box } from "@mui/material";

type PresetDesigns = {
  [key: string]: {
    innerText?: string;
    innerTextColor?: string;
    avatarText?: string;
    avatarTextColor?: string;
    primaryColor?: string;
    lightColor?: string;
    border?: string;
  };
};

const presets: PresetDesigns = {
  critical: {
    innerTextColor: "#000",
    avatarText: "C",
    avatarTextColor: "#fff",
    primaryColor: "#FF0000",
    lightColor: "#FBE6E5",
    border: "0.5px solid #FF0000",
  },
  high: {
    innerTextColor: "#000",
    avatarText: "H",
    avatarTextColor: "#fff",
    primaryColor: "#FF7A00",
    lightColor: "#FEECE2",
    border: "0.5px solid #FF0000",
  },
  medium: {
    innerTextColor: "#000",
    avatarText: "M",
    avatarTextColor: "#fff",
    primaryColor: "#EACA55",
    lightColor: "#FFFAF3",
    border: "0.5px solid #EACA55",
  },
  moderate: {
    innerTextColor: "#000",
    avatarText: "M",
    avatarTextColor: "#fff",
    primaryColor: "#EACA55",
    lightColor: "#FFFAF3",
    border: "0.5px solid #EACA55",
  },
  low: {
    innerTextColor: "#000",
    avatarText: "L",
    avatarTextColor: "#fff",
    primaryColor: "#4FD35D",
    lightColor: "#F0FBF0",
    border: "0.5px solid #4FD35D",
  },
  negligible: {
    innerTextColor: "#000",
    avatarText: "N",
    avatarTextColor: "#fff",
    primaryColor: "#7D7D7D",
    lightColor: "#EEEEEE",
    border: "0.5px solid #4FD35D",
  },
  unspecified: {
    innerTextColor: "#999",
    avatarText: "U",
    avatarTextColor: "#999",
    primaryColor: "#eee",
    lightColor: "none",
    border: "0.5px solid #ccc",
  },
  none: {
    innerText: "0",
    innerTextColor: "#999",
    avatarTextColor: "#999",
    primaryColor: "#eee",
    lightColor: "none",
    border: "0.5px solid #ccc",
  },
};

export default function VulnChip({
  preset = "",
  innerText = "",
  innerTextColor = "#999",
  avatarText = "",
  avatarTextColor = "#999",
  primaryColor = "#eee",
  lightColor = "none",
  border = "0.5px solid #ccc",
}: {
  preset?: string;
  innerText?: string;
  innerTextColor?: string;
  avatarText?: string;
  avatarTextColor?: string;
  primaryColor?: string;
  lightColor?: string;
  border?: string;
}) {
  preset = preset.toLowerCase().trimEnd();
  // Apply preset values based on input
  if (presets.hasOwnProperty(preset)) {
    const presetValues = presets[preset];
    innerText = presetValues.innerText || innerText;
    innerTextColor = presetValues.innerTextColor || innerTextColor;
    avatarText = presetValues.avatarText || avatarText;
    avatarTextColor = presetValues.avatarTextColor || avatarTextColor;
    primaryColor = presetValues.primaryColor || primaryColor;
    lightColor = presetValues.lightColor || lightColor;
  }

  const chipStyle = {
    minWidth: "48px",
    border: border,
    display: "inline-block",
    padding: "0px 5px 0px 3px",
    borderRadius: "3px",
    marginRight: "7px",
    lineHeight: "20px",
    background: lightColor,
  };

  const avatarStyle = {
    display: "inline",
    padding: "0px 3px",
    borderRadius: "2px",
    background: primaryColor,
    marginRight: "5px",
    color: avatarTextColor,
    fontWeight: "700",
    fontSize: "12px",
  };

  const innerTextStyle = {
    display: "inline",
    color: innerTextColor,
    lineHeight: "21px",
    overflowX: "hidden",
  };

  return (
    <Box sx={chipStyle}>
      <Box sx={avatarStyle}>{avatarText}</Box>
      <Box sx={innerTextStyle}>{innerText}</Box>
    </Box>
  );
}
