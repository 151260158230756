import { Alert, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Code, ConnectError } from "@bufbuild/connect";
import { PartialMessage } from "@bufbuild/protobuf";

import { ProjectContext } from "../App";
import { useClient } from "../api/client";
import { BodyWrapperProjectScoped } from "../components/BodyWrapperProjectScoped";
import { EdgeBitPrimaryButton } from "../components/EdgeBitPrimaryButton";
import { EdgeBitPublicAPIService } from "../pb/edgebit/platform/v1alpha/platform_connectweb";
import { SourceRepoService } from "../pb/edgebit/platform/v1alpha/source_repos_connectweb";
import { GitHubImportableRepos, GitHubRepository } from "../components/GitHubImportableRepos";
import { GlobalError } from "../components/GlobalError";
import { GitHubRepoToImport, SBOMGenerationSource } from "../pb/edgebit/platform/v1alpha/platform_pb";

import { sourceRepoFromPB } from "../features/repoDetailSlice";

export const GitHubImport = () => {
  // API client
  const client = useClient(EdgeBitPublicAPIService);
  const srcRepoClient = useClient(SourceRepoService);
  const project = useContext(ProjectContext);
  const [repos, setRepos] = useState<GitHubRepository[]>([]);
  const [connectivity, setConnectivity] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [enabled, setEnabled] = useState<boolean>(true);
  const navigate = useNavigate();
  const defaultSBOMSources = [
    SBOMGenerationSource.SBOM_GENERATION_SOURCE_UNSPECIFIED,
    SBOMGenerationSource.SBOM_GENERATION_SOURCE_SOURCE,
  ];

  const handleError = (err: any) => {
    if (err instanceof ConnectError) {
      if (err.code === Code.Unavailable) {
        setConnectivity(false);
        setError(null);
      } else if (err.code === Code.InvalidArgument) {
        setError(err.rawMessage);
      } else {
        setError("Unknown error");
      }
    } else {
      setError("Unexpected error");
      throw err;
    }
  };

  useEffect(() => {
    if (project) {
      const promise = async () => {
        try {
          setConnectivity(true);
          const res = await srcRepoClient.listSourceRepos({ projectId: project.id, skipWithComponents: true });
          setRepos(
            res.sourceRepos.map(sourceRepoFromPB).map((repo) => {
              return {
                repo: repo,
                selected: false,
                availSBOMSources: [],
                selectedSBOMSource: SBOMGenerationSource.SBOM_GENERATION_SOURCE_UNSPECIFIED,
              };
            }),
          );
        } catch (err) {
          handleError(err);
        }
      };

      promise();
    }
  }, [project, srcRepoClient]);

  // Do the import
  const handleImport = async () => {
    if (!project) {
      console.log("No project ID set, can't create token");
      return;
    }

    try {
      const pbRepos = repos.reduce((prev: PartialMessage<GitHubRepoToImport>[], repo) => {
        if (repo.selected) {
          prev.push({
            integrationId: repo.repo.integrationId,
            sbomSource: repo.selectedSBOMSource,
            sourceRepoId: repo.repo.id,
          });
        }

        return prev;
      }, []);

      setConnectivity(true);
      await client.importGitHubRepos({
        projectId: project.id,
        repos: pbRepos,
      });

      navigate("/components/");
    } catch (err) {
      handleError(err);
    }
  };

  useEffect(() => {
    if (project) {
      const promise = async () => {
        try {
          setConnectivity(true);
          const res = await client.hasGitHubIntegration({ projectId: project.id });
          setEnabled(res.present);
        } catch (err) {
          handleError(err);
        }
      };

      promise();
    }
  }, [project, client]);

  const handleRepoSelectionChange = async (changed: GitHubRepository) => {
    if (changed.selected) {
      try {
        setConnectivity(true);
        const repoInfo = await client.analyzeGitHubRepo({
          projectId: project?.id,
          integrationId: changed.repo.integrationId,
          owner: changed.repo.details.value?.owner,
          name: changed.repo.details.value?.repo,
        });
        const available = repoInfo.buildActionPresent
          ? [...defaultSBOMSources, SBOMGenerationSource.SBOM_GENERATION_SOURCE_BUILD]
          : defaultSBOMSources;

        changed.availSBOMSources = available;
        changed.selectedSBOMSource = repoInfo.buildActionPresent
          ? SBOMGenerationSource.SBOM_GENERATION_SOURCE_BUILD
          : SBOMGenerationSource.SBOM_GENERATION_SOURCE_SOURCE;

        setRepos([...repos]);
      } catch (err) {
        handleError(err);
      }
    }
  };

  const handleSBOMChange = async (changed: GitHubRepository) => {
    setRepos([...repos]);
  };

  useEffect(() => {
    document.title = "Import Repositories - EdgeBit";
  });

  return (
    <BodyWrapperProjectScoped>
      {!connectivity && <GlobalError message="Error communicating with backend" />}
      <Typography variant="h4" gutterBottom sx={{ marginBottom: "20px" }}>
        Import from GitHub
      </Typography>
      <Typography variant="body2" gutterBottom sx={{ "& ul": { margin: "5px 0", paddingLeft: "25px" } }}>
        During import:
        <ul>
          <li>an EdgeBit Component is created</li>
          <li>a Pull Request is made to configure each repository's GitHub Actions workflow</li>
          <li>PR checks and the EdgeBit bot are enabled</li>
        </ul>
        Read <a href="https://edgebit.io/docs/0.x/install-build/">the configuration guide</a> for more details.
      </Typography>
      <GitHubImportableRepos
        repos={repos}
        enabled={enabled}
        onSelectionChange={handleRepoSelectionChange}
        onSBOMChange={handleSBOMChange}
      />
      {error && (
        <Alert style={{ marginTop: 11 }} severity="error">
          {error}
        </Alert>
      )}
      <EdgeBitPrimaryButton
        type="submit"
        variant="outlined"
        size="medium"
        onClick={handleImport}
        sx={{ marginTop: "20px", marginBottom: "0px" }}
      >
        Import
      </EdgeBitPrimaryButton>
    </BodyWrapperProjectScoped>
  );
};
