import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState, ThunkExtra } from "../app/store";

export interface HasGitHubIntegration {
  present: boolean;
}

export interface VCSRepo {
  integrationID: string;
  owner: string;
  name: string;
  description: string;
  private: boolean;
  url: string;
  lastActivity: string;
}

interface GitHubState {
  githubEnabled: boolean;
  repos: VCSRepo[] | null;
  connectivity: boolean;
  projectID: string;
  status: "idle" | "loading" | "failed";
}

const initialState: GitHubState = {
  githubEnabled: false,
  repos: null,
  connectivity: true,
  projectID: "",
  status: "idle",
};

export const hasGitHubIntegration = createAsyncThunk<boolean, { projectId: string }, ThunkExtra>(
  "github/hasGitHubIntegration",
  async ({ projectId }, thunkAPI) => {
    try {
      const { apiClient } = thunkAPI.extra;
      const response = await apiClient.hasGitHubIntegration({ projectId });
      return response.present;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  },
);

export const listRepositories = createAsyncThunk<VCSRepo[], { projectId: string; integrationId: string }, ThunkExtra>(
  "github/listRepositories",
  async ({ projectId, integrationId }, thunkAPI) => {
    try {
      const { apiClient } = thunkAPI.extra;
      const response = await apiClient.listGitHubRepositories({
        projectId: projectId,
        integrationId: integrationId,
      });
      const repos: VCSRepo[] = response.repos.map((repo) => ({
        integrationID: repo.integrationId,
        owner: repo.owner,
        name: repo.name,
        description: repo.description,
        private: repo.private,
        url: repo.url,
        lastActivity: repo.lastActivity ? repo.lastActivity.toDate().toString() : "",
      }));
      return repos;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  },
);

export const githubSlice = createSlice({
  name: "githubIntegration",
  initialState,
  reducers: {
    setGitHubEnabled: (state, action: PayloadAction<boolean>) => {
      state.githubEnabled = action.payload;
    },
    setConnectivity: (state, action: PayloadAction<boolean>) => {
      state.connectivity = action.payload;
    },
    setProjectID: (state, action: PayloadAction<string>) => {
      state.projectID = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(hasGitHubIntegration.pending, (state) => {
        state.status = "loading";
      })
      .addCase(hasGitHubIntegration.fulfilled, (state, action) => {
        state.status = "idle";
        state.githubEnabled = action.payload;
      })
      .addCase(hasGitHubIntegration.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(listRepositories.pending, (state) => {
        state.status = "loading";
      })
      .addCase(listRepositories.fulfilled, (state, action) => {
        state.status = "idle";
        state.repos = action.payload;
      })
      .addCase(listRepositories.rejected, (state) => {
        state.status = "failed";
      });
  },
});

export const { setGitHubEnabled, setConnectivity, setProjectID } = githubSlice.actions;

export const selectConnectivity = (state: RootState) => state.githubIntegration.connectivity;
export const selectGitHubEnabled = (state: RootState) => state.githubIntegration.githubEnabled;
export const selectProjectID = (state: RootState) => state.githubIntegration.projectID;
export const selectRepositories = (state: RootState) => state.githubIntegration.repos;

export default githubSlice.reducer;
