import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState, ThunkExtra } from "../app/store";
import { Proposal, proposalFromPB, SourceRepoPackage, sourceRepoPackageFromPB } from "./repoDetailSlice";
import { Component } from "./componentDetailSlice";
import { CreateProposalResponse } from "../pb/edgebit/platform/v1alpha/source_repos_pb";

export interface TargetCategory {
  owner?: string;
  name?: string;
  displayName?: string;
  branch?: string;
  upgradeCategory?: string;
}

export interface TargetOptions {
  forceVersion?: string;
  legacyPeerDeps?: boolean;
  subDirectory?: string;
}

export const fetchProposals = createAsyncThunk<Proposal[], { projectId: string }, ThunkExtra>(
  "proposalList/fetchProposals",
  async ({ projectId }, thunkAPI) => {
    try {
      const { repoClient } = thunkAPI.extra;
      const response = await repoClient.listProposals({ projectId });
      return response.proposals.map((proposal) => {
        return proposalFromPB(proposal);
      });
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  },
);

export const fetchPackageSearchResults = createAsyncThunk<
  SourceRepoPackage[],
  { projectId: string; query: string },
  ThunkExtra
>("proposalList/fetchPackageSearchResults", async ({ projectId, query }, thunkAPI) => {
  try {
    const { repoClient } = thunkAPI.extra;
    const response = await repoClient.searchPackages({ projectId, query });
    return response.packages.map(sourceRepoPackageFromPB);
  } catch (err: any) {
    return thunkAPI.rejectWithValue(err.response.data);
  }
});

export const searchForTargetPackage = createAsyncThunk<
  SourceRepoPackage | undefined,
  { projectId: string; repoId: string; packageName: string },
  ThunkExtra
>("proposalList/searchForTargetPackage", async ({ projectId, repoId, packageName }, thunkAPI) => {
  try {
    const { repoClient } = thunkAPI.extra;
    const response = await repoClient.searchPackages({ projectId, query: packageName });
    const pkg = response.packages.find((pkg) => pkg.packageName === packageName && pkg.repoId === repoId);
    return pkg ? sourceRepoPackageFromPB(pkg) : undefined;
  } catch (err: any) {
    return thunkAPI.rejectWithValue(err.response.data);
  }
});

export const searchForPackageByIssuePackageName = createAsyncThunk<
  SourceRepoPackage | undefined,
  { projectId: string; packageName?: string; componentId?: string },
  ThunkExtra
>("proposalList/searchForPackageByIssuePackageName", async ({ projectId, packageName, componentId }, thunkAPI) => {
  try {
    const { repoClient } = thunkAPI.extra;
    const response = await repoClient.listSourceRepoPackageForComponentIssuePackageName({
      projectId,
      packageName: packageName,
      componentId: componentId,
    });
    const pkg = response.package;
    return pkg ? sourceRepoPackageFromPB(pkg) : undefined;
  } catch (err: any) {
    return thunkAPI.rejectWithValue(err.response.data);
  }
});

export const createProposal = createAsyncThunk<
  Proposal,
  {
    projectId: string;
    repoId: string;
    packageIds?: string[];
    autoPropose: boolean;
    category: TargetCategory;
    options?: TargetOptions;
    forceVersions?: { [key: string]: string };
  },
  ThunkExtra
>(
  "proposalList/createProposal",
  async ({ projectId, repoId, packageIds, autoPropose, category, options, forceVersions }, thunkAPI) => {
    try {
      const { repoClient } = thunkAPI.extra;
      let proposalType = category.upgradeCategory;
      let baseBranch = category.branch;
      let response: CreateProposalResponse;
      if (category.upgradeCategory === "requested") {
        response = await repoClient.createProposal({
          projectId,
          repoId,
          autoPropose,
          proposalType,
          packageIds,
          baseBranch,
          options,
          forceVersions,
        });
      } else {
        response = await repoClient.createProposal({
          projectId,
          repoId,
          autoPropose,
          proposalType,
          baseBranch,
          options,
        });
      }
      return proposalFromPB(response.proposal!);
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  },
);

export const fetchTargetComponent = createAsyncThunk<Component, { projectId: string; id: string }, ThunkExtra>(
  "proposalList/fetchComponent",
  async ({ projectId, id }, thunkAPI) => {
    try {
      const { apiClient } = thunkAPI.extra;
      const response = await apiClient.getComponent({ projectId, id });
      if (!response.component) {
        throw new Error("Component not found");
      }

      const component: Component = {
        sourceRepository: response.component?.sourceRepository,
        ...response.component,
        createdAt: response.component.createdAt ? String(response.component.createdAt.toDate()) : "",
        updatedAt: response.component.updatedAt ? String(response.component.updatedAt.toDate()) : "",
      };
      return component;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  },
);

interface ProposalListState {
  connectivity: boolean;
  value: number;
  projectID: string;
  search: string;
  searchResults: SourceRepoPackage[];
  targetPkg: SourceRepoPackage | undefined;
  targetCategory: TargetCategory | undefined;
  targetComponent: Component | undefined;
  targetRepoID: string | undefined;
  targetOptions: TargetOptions | undefined;
  openPullRequest: boolean;
  specificPackageModalOpen: boolean;
  upgradeCategoryModalOpen: boolean;
  creatingProposal: boolean;
  categoryMenuOpen: boolean;
  proposals: Proposal[];
  status: "idle" | "loading" | "failed";
}

const initialState: ProposalListState = {
  connectivity: true,
  value: 0,
  projectID: "",
  search: "",
  searchResults: [],
  targetPkg: undefined,
  targetCategory: undefined,
  targetComponent: undefined,
  targetRepoID: undefined,
  targetOptions: undefined,
  openPullRequest: true,
  specificPackageModalOpen: false,
  upgradeCategoryModalOpen: false,
  creatingProposal: false,
  categoryMenuOpen: false,
  proposals: [],
  status: "idle",
};

export const proposalListSlice = createSlice({
  name: "proposalList",
  initialState,
  reducers: {
    setConnectivity: (state, action: PayloadAction<boolean>) => {
      state.connectivity = action.payload;
    },
    setValue: (state, action: PayloadAction<number>) => {
      state.value = action.payload;
    },
    setProjectID: (state, action: PayloadAction<string>) => {
      state.projectID = action.payload;
    },
    setSearch: (state, action: PayloadAction<string>) => {
      state.search = action.payload;
    },
    setTargetPkg: (state, action: PayloadAction<SourceRepoPackage | undefined>) => {
      state.targetPkg = action.payload;
    },
    setTargetCategory: (state, action: PayloadAction<TargetCategory | undefined>) => {
      state.targetCategory = action.payload;
    },
    setTargetComponent: (state, action: PayloadAction<Component | undefined>) => {
      state.targetComponent = action.payload;
    },
    setTargetOptions: (state, action: PayloadAction<TargetOptions | undefined>) => {
      state.targetOptions = action.payload;
    },
    setTargetRepoID: (state, action: PayloadAction<string | undefined>) => {
      state.targetRepoID = action.payload;
    },
    setOpenPullRequest: (state, action: PayloadAction<boolean>) => {
      state.openPullRequest = action.payload;
    },
    openSpecificPackageModal: (state) => {
      state.specificPackageModalOpen = true;
    },
    closeSpecificPackageModal: (state) => {
      state.specificPackageModalOpen = false;
    },
    openUpgradeCategoryModal: (state) => {
      state.upgradeCategoryModalOpen = true;
    },
    closeUpgradeCategoryModal: (state) => {
      state.upgradeCategoryModalOpen = false;
    },
    openCategoryMenu: (state) => {
      state.categoryMenuOpen = true;
    },
    closeCategoryMenu: (state) => {
      state.categoryMenuOpen = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProposals.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchProposals.fulfilled, (state, action) => {
        state.proposals = action.payload;
        state.status = "idle";
      })
      .addCase(fetchProposals.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(fetchPackageSearchResults.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchPackageSearchResults.fulfilled, (state, action) => {
        state.searchResults = action.payload;
        state.status = "idle";
        state.connectivity = true;
      })
      .addCase(searchForTargetPackage.pending, (state) => {
        state.status = "loading";
      })
      .addCase(searchForTargetPackage.fulfilled, (state, action) => {
        state.targetPkg = action.payload;
        state.status = "idle";
        state.connectivity = true;
      })
      .addCase(searchForPackageByIssuePackageName.pending, (state) => {
        state.status = "loading";
      })
      .addCase(searchForPackageByIssuePackageName.fulfilled, (state, action) => {
        state.targetPkg = action.payload;
        state.status = "idle";
      })
      .addCase(createProposal.pending, (state) => {
        state.creatingProposal = true;
        state.status = "loading";
      })
      .addCase(createProposal.fulfilled, (state, action) => {
        state.creatingProposal = false;
        state.status = "idle";
      })
      .addCase(createProposal.rejected, (state) => {
        state.creatingProposal = false;
        state.status = "failed";
      })
      .addCase(fetchTargetComponent.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchTargetComponent.fulfilled, (state, action) => {
        state.targetComponent = action.payload;
        state.status = "idle";
      })
      .addCase(fetchTargetComponent.rejected, (state) => {
        state.status = "failed";
      });
  },
});

export const {
  setConnectivity,
  setValue,
  setProjectID,
  setSearch,
  setTargetPkg,
  setTargetOptions,
  setOpenPullRequest,
  setTargetRepoID,
  openSpecificPackageModal,
  closeSpecificPackageModal,
  openUpgradeCategoryModal,
  closeUpgradeCategoryModal,
  closeCategoryMenu,
  openCategoryMenu,
  setTargetCategory,
  setTargetComponent,
} = proposalListSlice.actions;

export const selectConnectivity = (state: RootState) => state.proposalList.connectivity;
export const selectValue = (state: RootState) => state.proposalList.value;
export const selectProjectID = (state: RootState) => state.proposalList.projectID;
export const selectSearch = (state: RootState) => state.proposalList.search;
export const selectTargetPkg = (state: RootState) => state.proposalList.targetPkg;
export const selectTargetOptions = (state: RootState) => state.proposalList.targetOptions;
export const selectTargetRepoID = (state: RootState) => state.proposalList.targetRepoID;
export const selectOpenPullRequest = (state: RootState) => state.proposalList.openPullRequest;
export const selectSpecificPackageModalOpen = (state: RootState) => state.proposalList.specificPackageModalOpen;
export const selectUpgradeCategoryModalOpen = (state: RootState) => state.proposalList.upgradeCategoryModalOpen;
export const selectCategoryMenuOpen = (state: RootState) => state.proposalList.categoryMenuOpen;
export const selectTargetCategory = (state: RootState) => state.proposalList.targetCategory;
export const selectTargetComponent = (state: RootState) => state.proposalList.targetComponent;
export const selectProposals = (state: RootState) => state.proposalList.proposals;
export const selectSearchResults = (state: RootState) => state.proposalList.searchResults;
export const selectCreatingProposal = (state: RootState) => state.proposalList.creatingProposal;

export default proposalListSlice.reducer;
