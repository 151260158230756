import {
  Box,
  Container,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

import { SourceRepoPackage, SourceRepo } from "../features/repoDetailSlice";
import { PackageType } from "../components/PackageType";

export const RepoPackageTable = (props: { repo: SourceRepo | undefined; packages: SourceRepoPackage[] | null }) => {
  return (
    <Container disableGutters>
      <TableContainer component={Paper} sx={{ border: "1px solid #ddd" }}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Type</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Installed Version</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.packages?.map((pkg: SourceRepoPackage) => (
              <TableRow key={pkg.id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                <TableCell component="th" scope="row">
                  <PackageType type={pkg.packageType} name={true} />
                </TableCell>
                <TableCell>{pkg.packageName}</TableCell>
                <TableCell>{pkg.packageVersion}</TableCell>
              </TableRow>
            ))}
            {!props.repo && (
              <TableRow>
                <TableCell colSpan={3}>
                  <Box
                    sx={{
                      textAlign: "center",
                      display: "block",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "650px",
                      marginTop: "10px",
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                  >
                    <Typography variant="h6" gutterBottom sx={{ display: "block" }}>
                      This component is not associated with a source repository
                    </Typography>
                    <Typography variant="body1" gutterBottom sx={{ display: "block", marginBottom: "10px" }}>
                      Connect the GitHub integration and associate with a repository under Settings
                    </Typography>
                  </Box>
                </TableCell>
              </TableRow>
            )}
            {props.repo && props.packages?.length === 0 && (
              <TableRow>
                <TableCell colSpan={3}>
                  <Box
                    sx={{
                      textAlign: "center",
                      display: "block",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "550px",
                      marginTop: "10px",
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                  >
                    <Typography variant="h6" gutterBottom sx={{ display: "block" }}>
                      Repository is being inventoried for packages
                    </Typography>
                    <Typography variant="body1" gutterBottom sx={{ display: "block", marginBottom: "10px" }}>
                      The inventory will be complete in just a few minutes
                    </Typography>
                  </Box>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
};
