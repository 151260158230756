import { Duration } from "@bufbuild/protobuf";
import SearchIcon from "@mui/icons-material/Search";
import { Alert, Grid, Link, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { SelectChangeEvent } from "@mui/material/Select";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import TextField from "@mui/material/TextField";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Tooltip from "@mui/material/Tooltip";
import { useContext, useEffect, useState } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { ProjectContext } from "../App";
import { useClient } from "../api/client";
import { downloadFile } from "../api/files";
import { BodyWrapperProjectScoped } from "../components/BodyWrapperProjectScoped";
import { ComponentIssuesTable } from "../components/ComponentIssuesTable";
import {
  ComponentSettings,
  ComponentSettingsData,
  generateLabelString,
  parseLabelString,
} from "../components/ComponentSettings";
import { ComponentUsageSBOMTable } from "../components/ComponentUsageSBOM";
import { VulnHistory } from "../components/ComponentVulnHistory";
import { DonutChart } from "../components/DonutChart";
import { EPSSActions } from "../components/EPSSActions";
import { EdgeBitPrimaryButton } from "../components/EdgeBitPrimaryButton";
import { GlobalError } from "../components/GlobalError";
import { IssueIgnoreModal } from "../components/IssueModifications";
import { LabelDisplay } from "../components/LabelDisplay";
import { OverviewBox } from "../components/OverviewBox";
import { SBOMTable } from "../components/SBOMTable";
import { UploadSBOMButton } from "../components/UploadSBOMButton";
import { RepoPackageTable } from "../components/RepoPackageTable";
import { EdgeBitPublicAPIService } from "../pb/edgebit/platform/v1alpha/platform_connectweb";
import {
  ComponentIssueState,
  IntegrationType,
  PolicyItem,
  SourceRepository as SourceRepositoryPB,
} from "../pb/edgebit/platform/v1alpha/platform_pb";
import {
  fetchSBOMs,
  fetchSBOMDownloadUrl,
  selectSBOMConnectivity,
  selectSBOMs,
  setConnectivity,
  fetchVexDownloadUrl,
  selectComponentIssues,
  listComponentIssues,
  ComponentIssue,
  fetchComponent,
  selectComponent,
  updateComponent,
  deleteComponent,
  fetchComponentTagsOverview,
  selectTagsOverview,
  fetchComponentIssueTrend,
  selectTrend,
  selectSuppressedByThreshold,
  selectSuppressionPercent,
  selectSuppressedByDormancy,
  fetchProjectIssueSLAPolicy,
  selectProjectIssueSLAPolicy,
  selectComponentSBOMTags,
  listComponentTags,
} from "../features/componentDetailSlice";
import { fetchRepoList, selectRepos } from "../features/repoListSlice";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { sourceRepoToSourceRepositoryPB, sourceRepositoryFromSourceRepoPB } from "./helpers/components";
import { CodeRepo } from "../components/CodeRepo";
import { OverviewBoxItem } from "../components/OverviewBoxItem";
import { OverviewBoxTextItem } from "../components/OverviewBoxTextItem";
import { SourceRepoLink } from "../components/SourceRepoLink";
import { selectPackages, sourceRepoFromPB, fetchPackages, scanRepo, selectScanning } from "../features/repoDetailSlice";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {value === index && (
        <Box sx={{ marginTop: "30px" }}>
          <Box>{children}</Box>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function getSuppressionLabel(labelValue: number, currentValue: number | undefined, desiredValue: number | undefined) {
  var labelString = "";

  // Raise or lower
  if (desiredValue && labelValue === desiredValue) {
    labelString = "Remain at ";
  } else if (desiredValue && labelValue > desiredValue) {
    labelString = "Raise to ";
  } else if (desiredValue && labelValue < desiredValue) {
    labelString = "Lower to ";
  }

  // Severity to string
  switch (labelValue) {
    case 0:
      labelString += "Unspecified";
      break;
    case 1:
      labelString += "Negligible";
      break;
    case 2:
      labelString += "Low";
      break;
    case 3:
      labelString += "Medium";
      break;
    case 4:
      labelString += "High";
      break;
    case 5:
      labelString += "Critical";
      break;
  }

  if (labelValue === currentValue) {
    labelString += " (current)";
  }

  return labelString;
}

function showSuppressionTimeSaved(count: number | null) {
  if (count) {
    var minutes = count * 50;
    var hours = Math.floor(minutes / 60);
    return hours + " hours";
  } else {
    return "0 hours";
  }
}

export function daysStringFromDuration(duration: Duration | undefined): string {
  if (!duration) {
    return "";
  }

  const durationInSeconds = duration.seconds ?? 0;
  const durationInDays = Math.floor(Number(durationInSeconds) / 86400);
  const durationString = durationInDays.toString();

  return durationString;
}

function sourceRepositoryURL(sourceRepo: SourceRepositoryPB): string {
  switch (sourceRepo.kind.case) {
    case "sourceRepoDetails": {
      switch (sourceRepo.kind.value.details.case) {
        case "githubRepoDetails":
          const { owner, repo } = sourceRepo.kind.value.details.value;
          return `https://github.com/${owner}/${repo}`;
      }
      break;
    }
    case "repoUrl":
      return sourceRepo.kind.value;
  }

  return "";
}

export const ComponentDetail = () => {
  const project = useContext(ProjectContext);
  const { componentId, tab } = useParams();

  // API client
  const client = useClient(EdgeBitPublicAPIService);

  const [modifyTagsFormOpen, setModifyTagsFormOpen] = useState<boolean>(false);
  const [desiredTags, setDesiredTags] = useState<{ tags: string } | null>(null);

  const [modifySuppressionFormOpen, setModifySuppressionFormOpen] = useState<boolean>(false);
  const [desiredSuppression, setDesiredSuppression] = useState<{
    id: string | undefined;
    desired: number;
    current: number;
    existingAutoSuppression: boolean;
  } | null>(null);

  const [componentSettingsData, setComponentSettingsData] = useState<ComponentSettingsData>({
    availSourceRepositories: [],
  });
  const [settingsFormError, setSettingsFormError] = useState<string | null>(null);

  const [policies, setPolicies] = useState<PolicyItem[] | undefined>(undefined);
  const [issueStateFilter, setIssueStateFilter] = useState<ComponentIssueState[]>([ComponentIssueState.OPEN]);
  const [ignoreFormOpen, setIgnoreFormOpen] = useState<boolean>(false);
  const [ignoreVuln, setIgnoreVuln] = useState<{
    id: string | undefined;
    comment: string | undefined;
    justification: string | undefined;
  } | null>(null);
  const [ignoreIssue, setIgnoreIssue] = useState<ComponentIssue | undefined>();
  const [showConfirmDeleteDialog, setShowConfirmDeleteDialog] = useState<boolean>(false);
  const [jiraOrgHostname, setJiraOrgHostname] = useState<string | undefined>(undefined);

  const dispatch = useAppDispatch();
  const connectivity = useAppSelector(selectSBOMConnectivity);
  const sboms = useAppSelector(selectSBOMs);
  const issues = useAppSelector(selectComponentIssues);
  const component = useAppSelector(selectComponent);
  const tagsOverview = useAppSelector(selectTagsOverview);
  const trend = useAppSelector(selectTrend);
  const suppressedByThreshold = useAppSelector(selectSuppressedByThreshold);
  const suppressedByDormancy = useAppSelector(selectSuppressedByDormancy);
  const suppressionPercent = useAppSelector(selectSuppressionPercent);
  const projectIssueSLAPolicy = useAppSelector(selectProjectIssueSLAPolicy);
  const componentSBOMTags = useAppSelector(selectComponentSBOMTags);
  const repos = useAppSelector(selectRepos);

  const scanning = useAppSelector(selectScanning);
  const packages = useAppSelector(selectPackages);
  const repo =
    component?.sourceRepository?.kind.case === "sourceRepoDetails"
      ? sourceRepoFromPB(component.sourceRepository.kind.value)
      : undefined;

  const triggerScan = () => {
    if (!project || !repo || !componentId) {
      return;
    }

    dispatch(scanRepo({ projectId: project.id, repoId: repo.id, componentId }));
  };

  // Default threshold = 1% chance of exploitation in the next 30 days
  const [epssThreshold] = useState(0.1);

  // Tab bar
  const tabContents: Record<string, { label: string; tabIndex: number }> = {
    overview: {
      label: "Overview",
      tabIndex: 0,
    },
    vulnerabilities: {
      label: "Vulnerabilities",
      tabIndex: 1,
    },
    sboms: {
      label: "SBOMs",
      tabIndex: 2,
    },
    inventory: {
      label: "Inventory",
      tabIndex: 3,
    },
    settings: {
      label: "Settings",
      tabIndex: 4,
    },
  };

  // Navigate to tab specified in URL parameter
  // If unset, tabgroup goes to 0 index
  const navigate = useNavigate();
  var currentTabIndex = 0;
  if (tab !== undefined) {
    currentTabIndex = tabContents[tab].tabIndex;
  }
  useEffect(() => {
    if (tab !== undefined) {
      navigate("/components/" + componentId + "/" + tab, { replace: true });
    }
  }, [componentId, tab, navigate]);

  const handleTabChange = (event: React.SyntheticEvent | undefined, newValue: number) => {
    navigate("/components/" + componentId + "/" + event?.currentTarget.textContent?.toLowerCase());
    document.querySelector("main")?.scrollTo(0, 0);
  };

  // Issue state filter
  const allStatesFilter = [ComponentIssueState.OPEN, ComponentIssueState.RESOLVED, ComponentIssueState.IGNORED];
  const openStatesFilter = [ComponentIssueState.OPEN];
  const closedStatesFilter = [ComponentIssueState.RESOLVED];
  const ignoredStatesFilter = [ComponentIssueState.IGNORED];
  const handleIssueStateChanged = (event: React.MouseEvent<HTMLElement>, newFilterStates: ComponentIssueState[]) => {
    setIssueStateFilter(newFilterStates);
  };

  const compareStates = (filterState: ComponentIssueState[], value: ComponentIssueState[]) => {
    // Work around a bug in the ToggleGroup where it can't compare array values
    // to dictate active state, only strings and other values
    return filterState.toString() === value.toString();
  };

  // Change SBOM tags modal
  const openChangeTagsForm = (id: string | undefined, tags: string | undefined) => {
    if (tags) {
      setDesiredTags({ tags: tags });
    }
    setModifyTagsFormOpen(true);
  };

  const closeChangeTagsForm = () => {
    setModifyTagsFormOpen(false);
  };

  // Update SBOMs after tag changed
  let refreshSBOMList = () => {
    if (!project) {
      return;
    }

    if (componentId) {
      dispatch(
        fetchSBOMs({
          projectId: project.id,
          componentId: componentId,
        }),
      );
    }
  };

  useEffect(refreshSBOMList, [client, componentId, dispatch, project]);

  // Handle SBOM Table Refresh
  const handleRefreshSBOMTrigger = () => {
    refreshSBOMList();
  };

  // Save Modify Tags Modal
  const handleModifyTagsSave = () => {
    if (!project) {
      console.log("No project ID set, can't create token");
      return;
    }

    // client.createOrgAccessToken({
    //     projectId: project.id,
    //     description: createAPITokenFormData?.description || 'New Build Token',
    // }).then(
    //     (res) => {
    //         setDesiredTags(null);
    //         setAPITokenModalValue(res);
    //     },
    //     (err) => {
    //         console.log(err);
    //         setConnectivity(false);
    //     }
    // );

    setModifyTagsFormOpen(false);
    refreshSBOMList();
  };

  const handleSBOMDownload = (sbomId: string | undefined) => {
    if (!project) {
      return;
    }

    if (sbomId) {
      dispatch(fetchSBOMDownloadUrl({ projectId: project.id, sbomId })).then((action) => {
        if (fetchSBOMDownloadUrl.fulfilled.match(action)) {
          downloadFile(action.payload);
        } else {
          console.log("Error fetching SBOM download URL");
        }
      });
    }
  };

  const handleVexDownload = (componentId: string | undefined) => {
    if (!project) {
      return;
    }

    if (componentId) {
      dispatch(fetchVexDownloadUrl({ projectId: project.id, componentId })).then((action) => {
        if (fetchVexDownloadUrl.fulfilled.match(action)) {
          downloadFile(action.payload);
        } else {
          console.log("Error fetching Vex download URL");
        }
      });
    }
  };

  // Vuln Suppression modal
  const openSuppressionForm = (id: string | undefined) => {
    setDesiredSuppression({
      id: id,
      desired: 2,
      current: 2,
      existingAutoSuppression: true,
    });
    setModifySuppressionFormOpen(true);
  };

  const closeSuppressionForm = () => {
    setModifySuppressionFormOpen(false);
  };

  const handleSupppressionChange = (event: SelectChangeEvent) => {
    if (desiredSuppression) {
      var object = desiredSuppression;
      object.desired = Number.parseInt(event.target.value as string);
      setDesiredSuppression(object);
    } else {
      console.log("No state to mutate");
    }
  };

  // Save Suppression Modal
  const handleSuppressionSave = () => {
    if (!project) {
      console.log("No project ID set, can't create token");
      return;
    }

    // client.createOrgAccessToken({
    //     projectId: project.id,
    //     description: createAPITokenFormData?.description || 'New Build Token',
    // }).then(
    //     (res) => {
    //         setDesiredTags(null);
    //         setAPITokenModalValue(res);
    //     },
    //     (err) => {
    //         console.log(err);
    //         setConnectivity(false);
    //     }
    // );

    setModifySuppressionFormOpen(false);
  };

  // Vuln Ignore modal
  const openIgnoreForm = (issue: ComponentIssue | undefined) => {
    setIgnoreVuln({ id: issue?.id, comment: "", justification: "" });
    setIgnoreIssue(issue);
    setIgnoreFormOpen(true);
  };

  const closeIgnoreForm = () => {
    setIgnoreFormOpen(false);
  };

  const handleRefreshIssuesTrigger = () => {
    refreshIssues();
  };

  const refreshIssues = () => {
    if (!project) {
      return;
    }

    if (componentId !== undefined) {
      dispatch(listComponentIssues({ projectId: project.id, componentId: componentId, states: issueStateFilter }));
    } else {
      console.log("No component ID set, can't fetch issues");
    }
  };

  useEffect(() => {
    if (!project) {
      return;
    }

    if (componentId) {
      dispatch(fetchRepoList({ projectId: project.id }));
      dispatch(fetchComponent({ projectId: project.id, componentId: componentId }));

      dispatch(
        fetchComponentTagsOverview({
          projectId: project.id,
          componentId: componentId,
        }),
      );

      dispatch(
        fetchComponentIssueTrend({
          projectId: project.id,
          componentId: componentId,
        }),
      );

      dispatch(fetchProjectIssueSLAPolicy({ projectId: project.id }));
    }
  }, [project, componentId, dispatch]);

  useEffect(() => {
    if (!project || !componentId) {
      return;
    }

    dispatch(fetchPackages({ projectId: project.id, componentId }));
  }, [project, componentId, dispatch]);

  useEffect(() => {
    const [selectedSourceRepository, customSourceRepository] = sourceRepositoryFromSourceRepoPB(
      component?.sourceRepository,
    );

    setComponentSettingsData({
      displayName: component?.displayName || "",
      name: component?.name || "",
      labelString: generateLabelString(component?.labels || {}),
      machineQueryString: component?.machineSelector,
      autoUpdateName: false,
      defaultTagName: component?.defaultTagName || "",
      availSourceRepositories: repos || [],
      selectedSourceRepository: selectedSourceRepository,
      customSourceRepository: customSourceRepository,
      policyDormant: component?.policyDormantPackage,
      policyIgnoreSeverityThreshold: component?.policyIgnoreSeverityThreshold,
      subdir: component?.subdir,
    });
  }, [component, repos]);

  useEffect(refreshIssues, [client, componentId, project, issueStateFilter, dispatch]);

  const handleComponentSettingsSave = () => {
    setSettingsFormError(null);

    if (!project) {
      console.log("No project ID set, can't create token");
      return;
    }

    if (componentId) {
      dispatch(
        updateComponent({
          projectId: project.id,
          componentId: componentId,
          name: componentSettingsData?.name || "",
          displayName: componentSettingsData?.displayName || "",
          labels: parseLabelString(componentSettingsData?.labelString || ""),
          machineSelector: componentSettingsData?.machineQueryString || "",
          defaultTagName: componentSettingsData?.defaultTagName || "",
          sourceRepository: sourceRepoToSourceRepositoryPB(
            repos || [],
            componentSettingsData?.selectedSourceRepository,
            componentSettingsData?.customSourceRepository,
          ),
          policyDormantPackage: componentSettingsData?.policyDormant || 0,
          policyIgnoreSeverityThreshold: componentSettingsData?.policyIgnoreSeverityThreshold || 0,
          subdir: componentSettingsData?.subdir || "",
        }),
      );

      const [selectedSourceRepository, customSourceRepository] = sourceRepositoryFromSourceRepoPB(
        component?.sourceRepository,
      );

      setComponentSettingsData({
        displayName: component?.displayName || "",
        name: component?.name || "",
        labelString: generateLabelString(component?.labels || {}),
        machineQueryString: component?.machineSelector,
        autoUpdateName: false,
        defaultTagName: component?.defaultTagName || "",
        availSourceRepositories: repos || [],
        selectedSourceRepository: selectedSourceRepository,
        customSourceRepository: customSourceRepository,
        policyDormant: component?.policyDormantPackage,
        policyIgnoreSeverityThreshold: component?.policyIgnoreSeverityThreshold,
        subdir: component?.subdir,
      });

      // Open overview tab and scroll up
      navigate("/components/" + componentId + "/overview");
      document.querySelector("main")?.scrollTo(0, 0);
    }
  };

  const handleDeleteComponent = async () => {
    setSettingsFormError(null);
    if (project && componentId) {
      dispatch(deleteComponent({ projectId: project?.id, componentId: componentId }));
      navigate(`/components`);
    }
  };

  // Fetch integrations to populate Jira links
  // This assumes that there is only one Jira integration per org
  useEffect(() => {
    const fetch = async () => {
      try {
        const integrations = (await client.listIntegrations({})).integrations;
        for (const i of integrations) {
          switch (i.type) {
            case IntegrationType.JIRA:
              setJiraOrgHostname(i.account);
          }
        }
      } catch (err) {
        console.log(err);
        setConnectivity(false);
      }
    };

    fetch();
  }, [client]);

  useEffect(() => {
    if (!component) {
      document.title = componentId + " / Components";
    } else {
      document.title = component.displayName + " / Components";
    }
  }, [componentId, component]);

  useEffect(() => {
    if (!project) {
      return;
    }

    if (policies === undefined) {
      client.getServerSBOMRoutingPolicy({ projectId: project.id }).then(
        (res) => {
          setPolicies(res.policy?.policyItems);
        },
        (err) => {
          console.log(err);
          setConnectivity(false);
        },
      );
    }
  }, [project, client, componentId, policies]);

  useEffect(() => {
    if (!project) {
      return;
    }

    if (componentId) {
      dispatch(listComponentTags({ projectId: project.id, componentId: componentId }));
    }
  }, [project, client, componentId, dispatch]);

  if (!component) {
    return (
      <BodyWrapperProjectScoped>
        <Typography variant="h4" gutterBottom>
          Components /
        </Typography>
      </BodyWrapperProjectScoped>
    );
  }

  const repoURL = component.sourceRepository ? sourceRepositoryURL(component.sourceRepository) : "";

  return (
    <BodyWrapperProjectScoped>
      {!connectivity && <GlobalError message="Error communicating with backend" />}
      <Typography variant="h4" gutterBottom>
        Components / {component.displayName}
      </Typography>
      <Box sx={{ width: "100%" }}>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            margin: "0 -40px 0px -40px",
          }}
        >
          <Tabs
            value={currentTabIndex}
            onChange={handleTabChange}
            aria-label="Component Navigation"
            sx={{ padding: "0 40px" }}
          >
            {Object.entries(tabContents).map(([key, tab]) => (
              <Tab label={tab.label} {...a11yProps(tab.tabIndex)} key={key} />
            ))}
          </Tabs>
        </Box>
        {/* Modify SBOM Tags Modal */}
        <Dialog
          open={modifyTagsFormOpen ? true : false}
          onClose={closeChangeTagsForm}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Modify SBOM Tags</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">Add or remove tags for this SBOM:</DialogContentText>
            <TextField
              id="outlined-basic"
              label="Tags"
              variant="outlined"
              focused
              value={desiredTags?.tags || "Loading"}
              fullWidth
              sx={{ marginTop: "20px" }}
            />
          </DialogContent>
          <DialogActions
            sx={{ justifyContent: "flex-start", borderTop: "1px solid #ddd", padding: "10px 10px 10px 23px" }}
          >
            <EdgeBitPrimaryButton onClick={handleModifyTagsSave} disabled={true}>
              Save Tags
            </EdgeBitPrimaryButton>
            <Button onClick={closeChangeTagsForm} autoFocus>
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
        {/* Vuln Suppression Modal */}
        <Dialog
          open={modifySuppressionFormOpen ? true : false}
          onClose={closeSuppressionForm}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Suppress Vulnerability</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Modify the severity level of this specific vulnerability for this component.
              <br />
              {desiredSuppression && desiredSuppression.id && (
                <Link component={NavLink} to={"/machines/"} sx={{ marginTop: "10px", display: "block" }}>
                  CVE {desiredSuppression?.id}
                </Link>
              )}
              {desiredSuppression?.existingAutoSuppression &&
                "This severity has already been automatically modified by EdgeBit."}
            </DialogContentText>
            <FormControl>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue={desiredSuppression?.current}
                value={desiredSuppression?.desired}
                onChange={handleSupppressionChange}
                name="radio-buttons-group"
              >
                <FormControlLabel
                  value="5"
                  control={<Radio />}
                  label={getSuppressionLabel(5, desiredSuppression?.desired, desiredSuppression?.current)}
                />
                <FormControlLabel
                  value="4"
                  control={<Radio />}
                  label={getSuppressionLabel(4, desiredSuppression?.desired, desiredSuppression?.current)}
                />
                <FormControlLabel
                  value="3"
                  control={<Radio />}
                  label={getSuppressionLabel(3, desiredSuppression?.desired, desiredSuppression?.current)}
                />
                <FormControlLabel
                  value="2"
                  control={<Radio />}
                  label={getSuppressionLabel(2, desiredSuppression?.desired, desiredSuppression?.current)}
                />
                <FormControlLabel
                  value="1"
                  control={<Radio />}
                  label={getSuppressionLabel(1, desiredSuppression?.desired, desiredSuppression?.current)}
                />
              </RadioGroup>
            </FormControl>
          </DialogContent>
          <DialogActions
            sx={{ justifyContent: "flex-start", borderTop: "1px solid #ddd", padding: "10px 10px 10px 23px" }}
          >
            <EdgeBitPrimaryButton onClick={handleSuppressionSave}>Apply Suppression</EdgeBitPrimaryButton>
            <Button onClick={closeSuppressionForm} autoFocus>
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
        {/* Vuln Ignore Modal */}
        <IssueIgnoreModal
          ignoreFormOpen={ignoreFormOpen}
          setIgnoreVuln={setIgnoreVuln}
          desiredSuppression={desiredSuppression}
          issue={ignoreIssue}
          ignoreVuln={ignoreVuln}
          closeIgnoreForm={closeIgnoreForm}
          project={project}
          componentId={componentId}
          refreshAfterSave={handleRefreshIssuesTrigger}
        />

        <Dialog open={showConfirmDeleteDialog} onClose={() => setShowConfirmDeleteDialog(false)}>
          <DialogTitle>Delete Component</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Deleting this component will permanently delete all of the SBOMs, vulnerabilities, and other data
              associated with it. Are you sure you want to delete this component?
            </DialogContentText>
          </DialogContent>
          <DialogActions
            sx={{ justifyContent: "flex-start", borderTop: "1px solid #ddd", padding: "10px 10px 10px 23px" }}
          >
            <EdgeBitPrimaryButton onClick={handleDeleteComponent}>Delete Component</EdgeBitPrimaryButton>
            <Button onClick={() => setShowConfirmDeleteDialog(false)}>Cancel</Button>
          </DialogActions>
        </Dialog>
        <TabPanel value={currentTabIndex} index={0}>
          <Box sx={{ marginTop: "40px" }}>
            <OverviewBox title="Component Details">
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <Typography variant="h6" sx={{ fontSize: "14px", marginTop: "10px" }}>
                    Component Labels
                  </Typography>
                  <Box sx={{ fontSize: "14px", marginBottom: "10px" }}>
                    <LabelDisplay labels={component?.labels}></LabelDisplay>
                  </Box>
                  <Typography variant="h6" sx={{ fontSize: "14px", marginTop: "10px" }}>
                    Source Repository
                  </Typography>
                  <Typography
                    component={"div"}
                    variant="body1"
                    sx={{
                      fontSize: "14px",
                      marginBottom: "10px",
                      wordWrap: "break-word",
                    }}
                  >
                    {!repoURL ? (
                      <>
                        <Box sx={{ color: "#999", display: "inline-block" }}>No repository set</Box>
                      </>
                    ) : (
                      <>
                        <Link href={repoURL}>{repoURL}</Link>
                      </>
                    )}
                  </Typography>
                  <Typography variant="h6" sx={{ fontSize: "14px", marginTop: "10px" }}>
                    SLAs for Remediation
                  </Typography>
                  {projectIssueSLAPolicy &&
                  Object.entries(projectIssueSLAPolicy).filter(([_, value]) => value !== "").length > 0 ? (
                    <>
                      {Object.entries(projectIssueSLAPolicy)
                        .filter(([_, value]) => value !== "")
                        .map(([key, value]) => (
                          <Typography
                            variant="body1"
                            sx={{
                              fontSize: "14px",
                              marginBottom: "0px",
                              textTransform: "capitalize",
                            }}
                            key={key}
                          >
                            {key.substring(0, key.length - 3)} CVEs:{" "}
                            <Box component="span" sx={{ display: "inline-block" }}>
                              {value} days
                            </Box>
                          </Typography>
                        ))}
                    </>
                  ) : (
                    <>
                      <Typography
                        variant="body1"
                        sx={{
                          fontSize: "14px",
                          marginBottom: "10px",
                          color: "#999",
                        }}
                      >
                        No SLA set for project
                      </Typography>
                    </>
                  )}
                  {policies &&
                    policies.filter((policy: PolicyItem, index: number) => policy.componentId === componentId).length >
                      0 && (
                      <>
                        <Typography variant="h6" sx={{ fontSize: "14px", marginTop: "10px" }}>
                          Machine Mapping Queries
                        </Typography>
                        {policies
                          ?.filter((policy: PolicyItem, index: number) => policy.componentId === componentId)
                          .map((policy: PolicyItem, index: number) => (
                            <Typography component={"div"} variant="body1" sx={{ fontSize: "14px" }} key={index}>
                              {policy.labelSelector.length === 0 ? (
                                <>
                                  <SearchIcon
                                    sx={{
                                      fontSize: "20px",
                                      verticalAlign: "middle",
                                    }}
                                  />
                                  <Box
                                    sx={{
                                      color: "#999",
                                      display: "inline-block",
                                    }}
                                  >
                                    Empty selector (matches all)
                                  </Box>
                                </>
                              ) : (
                                <>
                                  <SearchIcon
                                    sx={{
                                      fontSize: "20px",
                                      verticalAlign: "middle",
                                    }}
                                  />
                                  {policy.labelSelector}
                                </>
                              )}
                            </Typography>
                          ))}
                        <Typography variant="h6" sx={{ fontSize: "14px" }}>
                          <Link component={NavLink} to={"/projects/" + project?.id}>
                            View Mapping Policies
                          </Link>
                        </Typography>
                      </>
                    )}
                </Grid>
                <Grid item xs={12} sm={12} md={8} lg={8}>
                  <Typography variant="h6" sx={{ fontSize: "14px", marginTop: "10px" }}>
                    Vulnerability Trend
                  </Typography>
                  <VulnHistory type="preview" trend={trend}></VulnHistory>
                  <Typography variant="h6" sx={{ fontSize: "14px", marginTop: "10px" }}>
                    Audit Artifacts
                  </Typography>

                  <Typography component={"div"} variant="body1" sx={{ fontSize: "14px", color: "#999" }}>
                    Download artifacts for tag "{component.defaultTagName}"
                  </Typography>
                  <Grid container direction="row" spacing={2} sx={{ marginTop: "0px", marginBottom: "5px" }}>
                    <Grid item>
                      {/* Handle not having a latest SBOM */}
                      {tagsOverview?.defaultSbom?.sbom?.id ? (
                        <Button
                          variant="outlined"
                          size="small"
                          onClick={() => handleSBOMDownload(tagsOverview?.defaultSbom?.sbom?.id)}
                          disabled={tagsOverview?.defaultSbom?.sbom?.id ? false : true}
                        >
                          Download SBOM
                        </Button>
                      ) : (
                        <Tooltip title={"You don't have an SBOM tagged " + component.defaultTagName + " to download"}>
                          <span>
                            <Button variant="outlined" size="small" disabled={true}>
                              Download SBOM
                            </Button>
                          </span>
                        </Tooltip>
                      )}
                    </Grid>
                    <Grid item>
                      <Button variant="outlined" size="small" onClick={() => handleVexDownload(componentId)}>
                        Download VEX report
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </OverviewBox>
          </Box>
          <Typography variant="h6" sx={{ marginBottom: "10px" }}>
            Usage Overview
          </Typography>
          <ComponentUsageSBOMTable
            tagsOverview={tagsOverview}
            handleTagModal={openChangeTagsForm}
            refreshSBOMs={handleRefreshSBOMTrigger}
          />
        </TabPanel>
        <TabPanel value={currentTabIndex} index={1}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={7} sx={{ marginTop: "0px" }}>
              <OverviewBox title="Vulnerability Trend">
                <VulnHistory type="full" trend={trend}></VulnHistory>
              </OverviewBox>
            </Grid>
            <Grid item xs={12} sm={12} md={5}>
              <OverviewBox title="Suppressions">
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <DonutChart percent={suppressionPercent / 100} count={suppressionPercent + "%"}></DonutChart>
                  </Grid>
                  <Grid item xs={8}>
                    <p>
                      {suppressedByThreshold !== null ? suppressedByThreshold : "-"} vulnerabilities were suppressed by
                      threshold policy.
                      <br />
                      <br />
                      {suppressedByDormancy !== null ? suppressedByDormancy : "-"} vulnerabilities were suppressed for
                      being dormant
                      {suppressedByDormancy !== null &&
                        suppressedByDormancy > 0 &&
                        ", saving approx " + showSuppressionTimeSaved(suppressedByDormancy) + " of triage"}
                      .<br />
                      <br />
                      <Link component={NavLink} to={"/components/" + componentId + "/settings#ignore"}>
                        Configure Policies
                      </Link>
                    </p>
                  </Grid>
                </Grid>
              </OverviewBox>
              <OverviewBox title="Exploit Prediction (EPSS)" sx={{ maxHeight: "305px", overflow: "auto" }}>
                <Box sx={{ fontSize: "12px", marginBottom: "10px" }}>
                  AI-based measure of the probability of exploitation in the next 30 days.
                </Box>
                {issues && <EPSSActions issues={issues} threshold={epssThreshold} />}
              </OverviewBox>
            </Grid>
          </Grid>
          <Typography variant="h6" sx={{ marginBottom: "20px" }}>
            Vulnerabilities ({issues?.length})
          </Typography>
          <ToggleButtonGroup
            size="small"
            color="primary"
            value={issueStateFilter}
            exclusive
            onChange={handleIssueStateChanged}
            aria-label="Component Issue State"
            sx={{ marginRight: "20px" }}
          >
            <ToggleButton value={allStatesFilter} selected={compareStates(issueStateFilter, allStatesFilter)}>
              All States
            </ToggleButton>
            <ToggleButton value={openStatesFilter} selected={compareStates(issueStateFilter, openStatesFilter)}>
              Open Issues
            </ToggleButton>
            <ToggleButton value={closedStatesFilter} selected={compareStates(issueStateFilter, closedStatesFilter)}>
              Resolved
            </ToggleButton>
            <ToggleButton value={ignoredStatesFilter} selected={compareStates(issueStateFilter, ignoredStatesFilter)}>
              Ignored
            </ToggleButton>
          </ToggleButtonGroup>
          <ComponentIssuesTable
            issues={issues}
            component={component}
            handleSuppressionModal={openSuppressionForm}
            handleIgnoreModal={openIgnoreForm}
            jiraOrgHostname={jiraOrgHostname}
          />
        </TabPanel>
        <TabPanel value={currentTabIndex} index={2}>
          <Typography variant="h6" sx={{ marginBottom: "20px" }}>
            SBOM History
          </Typography>
          {project && componentId && <UploadSBOMButton projectId={project.id} givenComponentId={componentId} />}
          {policies &&
          policies.filter((policy: PolicyItem, index: number) => policy.componentId === componentId).length > 0 ? (
            <SBOMTable sboms={sboms} showMachine={true}></SBOMTable>
          ) : (
            <SBOMTable sboms={sboms} showTags={true} tags={componentSBOMTags}></SBOMTable>
          )}
        </TabPanel>
        <TabPanel value={currentTabIndex} index={3}>
          <OverviewBox title="Source Repository">
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <OverviewBoxItem label="Repository Name" isEmpty={!repo} emptyMessage="None">
                  <CodeRepo repo={repo ? repo : undefined} />
                </OverviewBoxItem>
                <OverviewBoxTextItem label="Default Branch" emptyMessage="None" text={repo?.targetBranch} />
                <OverviewBoxItem label="Link" emptyMessage="None" isEmpty={repo?.details.case === undefined}>
                  <SourceRepoLink details={repo?.details} />
                </OverviewBoxItem>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <OverviewBoxTextItem label="Repository ID" emptyMessage="None" text={repo?.id} />
                <OverviewBoxTextItem
                  label="Repository Type"
                  emptyMessage="None"
                  text={
                    repo && repo.details.case
                      ? {
                          githubRepoDetails: "GitHub",
                        }[repo.details.case]
                      : undefined
                  }
                />
              </Grid>
            </Grid>
          </OverviewBox>
          <Typography variant="h6" sx={{ marginBottom: "10px" }}>
            Package Inventory
          </Typography>
          <Button
            type="submit"
            variant="outlined"
            size="medium"
            disabled={scanning}
            sx={{ marginTop: "10px", marginBottom: "20px", marginRight: "20px" }}
            onClick={triggerScan}
          >
            Re-Scan Repo
          </Button>
          <RepoPackageTable repo={repo} packages={packages} />
        </TabPanel>
        <TabPanel value={currentTabIndex} index={4}>
          <Typography variant="h4" gutterBottom sx={{ fontSize: "18px" }}>
            CLI Command
          </Typography>
          <Typography variant="body2" gutterBottom sx={{ fontSize: "14px", color: "#999" }}>
            Use this command to upload SBOMs from your pipeline when building the code for this component.
          </Typography>
          <Box
            component="pre"
            sx={{
              whiteSpace: "pre-wrap",
              fontSize: "13px",
              textAlign: "left",
              fontWeight: "600",
              background: "#333",
              color: "#fff",
              padding: "5px 10px",
              borderRadius: "3px",
              minWidth: "400px",
              display: "inline-block",
            }}
          >
            $ ebctl upload-sbom \<br />
            &nbsp;&nbsp;--component={component.name} \<br />
            &nbsp;&nbsp;--tag=latest \<br />
            &nbsp;&nbsp;--tag=v1.2.3 \<br />
            &nbsp;&nbsp;--commit=94034fff8c1250b734223e74d72c6257a66060c0 \
            <br />
            &nbsp;&nbsp;--image-tag=registry.example.com/foo:latest \<br />
            &nbsp;&nbsp;--repo=github.com/example/foo \<br />
            &nbsp;&nbsp;filename.spdx
          </Box>

          <ComponentSettings data={componentSettingsData} onChange={setComponentSettingsData} showAdvanced={true} />

          <Box
            sx={{
              borderTop: "1px solid #ddd",
              marginTop: "20px",
              paddingTop: "20px",
            }}
          />
          {settingsFormError && (
            <Alert style={{ marginTop: 11 }} severity="error">
              {settingsFormError}
            </Alert>
          )}
          <EdgeBitPrimaryButton
            type="submit"
            variant="outlined"
            size="medium"
            sx={{ marginTop: "20px", marginBottom: "0px" }}
            onClick={handleComponentSettingsSave}
          >
            Save Component
          </EdgeBitPrimaryButton>
          <Button
            type="submit"
            variant="outlined"
            size="medium"
            color="error"
            sx={{ marginTop: "20px", marginBottom: "0px", marginLeft: "15px", float: "right" }}
            onClick={() => setShowConfirmDeleteDialog(true)}
          >
            Delete Component
          </Button>
        </TabPanel>
      </Box>
    </BodyWrapperProjectScoped>
  );
};
