import { Button, Grid, LinearProgress, Link, Typography } from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import Box from "@mui/material/Box";
import { useContext, useEffect, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import { useClient } from "../api/client";
import { BodyWrapperProjectScoped } from "../components/BodyWrapperProjectScoped";
import { GlobalError } from "../components/GlobalError";
import { OverviewBox } from "../components/OverviewBox";
import { EdgeBitPublicAPIService } from "../pb/edgebit/platform/v1alpha/platform_connectweb";
import { ProjectContext } from "../App";
import {
  ComponentIssueState,
  ComponentIssueStateChange,
  GetSBOMInventoryResponse,
  SBOMInventoryPackage,
  VulnFixState,
  VulnSeverity,
  IntegrationType,
} from "../pb/edgebit/platform/v1alpha/platform_pb";
import VulnChip from "../components/VulnChip";
import { PackageType } from "../components/PackageType";
import { ComponentIssueHistoryTable } from "../components/ComponentIssueHistoryTable";
import RelativeTimestamp from "../components/RelativeTimestamp";
import FormattedTimestamp from "../components/FormattedTimestamp";
import SubdirectoryArrowRightIcon from "@mui/icons-material/SubdirectoryArrowRight";
import { getJiraIssueID, getJiraIssueLink } from "../utils/jira";
import { IssueIgnoreModal, IssueModificationMenu } from "../components/IssueModifications";
import { ComponentIssue, Component, componentFromPB } from "../features/componentDetailSlice";
import {
  openSpecificPackageModal,
  searchForPackageByIssuePackageName,
  selectProjectID,
  setProjectID,
  setTargetCategory,
  setTargetOptions,
  setTargetComponent,
} from "../features/proposalListSlice";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { FeatureFlagGate } from "../components/FeatureFlagGate";
import { EdgeBitPrimaryButton } from "../components/EdgeBitPrimaryButton";
import useProjectId from "../hooks/useProjectId";
import { DialogTriggerPackageAnalysis } from "../components/DialogTriggerPackageAnalysis";

function getPackageFromName(targetPackageName: string, inventory: GetSBOMInventoryResponse) {
  return inventory.packages.find((pkg) => pkg.packageName === targetPackageName);
}

function getPackageFromId(targetPackageId: string, inventory: GetSBOMInventoryResponse) {
  return inventory.packages.find((pkg) => pkg.packageId === targetPackageId);
}

function RelatedPackage(props: { packages: SBOMInventoryPackage[]; title: string; level: number; loading: boolean }) {
  const margin = 30 * props.level;

  return (
    <Box sx={{ display: "flex", flexDirection: "row" }}>
      {props.level >= 1 && (
        <SubdirectoryArrowRightIcon
          sx={{
            alignSelft: "stretch",
            marginTop: "8px",
            marginLeft: margin + "px",
            color: "#ccc",
          }}
        />
      )}
      <Box sx={{ flexDirection: "column" }}>
        {props.packages.map((pkg) => (
          <Box
            sx={{
              border: "1px solid #eee",
              padding: "3px 10px",
              marginLeft: props.level >= 1 ? "5px" : "0px",
              marginTop: "10px",
              display: "inline-block",
            }}
            key={pkg.packageName}
          >
            <Box sx={{ display: "inline", fontWeight: "500", marginRight: "5px" }}>{props.title}:</Box>
            {!props.loading && props.packages.length > 0 && (
              <Box sx={{ display: "inline" }}>
                <PackageType type={pkg.packageType} />
                <Box sx={{ display: "inline-block", wordWrap: "break-word" }}>{pkg.packageName}</Box>
                {pkg.packageVersion && " (" + pkg.packageVersion + ")"}
              </Box>
            )}
          </Box>
        ))}
        {props.loading && (
          <Box
            sx={{
              border: "1px solid #eee",
              padding: "3px 10px",
              marginLeft: props.level >= 1 ? "5px" : "0px",
              marginTop: "10px",
              display: "inline-block",
            }}
          >
            <Box sx={{ display: "inline", fontWeight: "500", marginRight: "5px" }}>{props.title}:</Box>
            <Box sx={{ display: "inline", color: "#999" }}>Loading</Box>
          </Box>
        )}
        {!props.loading && props.packages.length === 0 && (
          <Box
            sx={{
              border: "1px solid #eee",
              padding: "3px 10px",
              marginLeft: props.level >= 1 ? "5px" : "0px",
              marginTop: "10px",
              display: "inline-block",
            }}
          >
            <Box sx={{ display: "inline", fontWeight: "500", marginRight: "5px" }}>{props.title}:</Box>
            <Box sx={{ display: "inline", color: "#999" }}>None</Box>
          </Box>
        )}
      </Box>
    </Box>
  );
}

export const ComponentIssueDetail = () => {
  const project = useContext(ProjectContext);
  const { componentId, issueId } = useParams();

  // API client
  const client = useClient(EdgeBitPublicAPIService);
  const [connectivity, setConnectivity] = useState<boolean>(true);

  const [component, setComponent] = useState<Component | undefined>(undefined);
  const [issue, setIssue] = useState<ComponentIssue | undefined>(undefined);
  const [issueStateChanges, setIssueStateChanges] = useState<ComponentIssueStateChange[] | undefined>(undefined);
  const [jiraOrgHostname, setJiraOrgHostname] = useState<string | undefined>(undefined);

  const [defaultSBOM, setDefaultSBOM] = useState<string | undefined | null>(undefined);
  const [currentPackage, setCurrentPackage] = useState<SBOMInventoryPackage[]>([]);
  const [currentAncestor, setCurrentAncestor] = useState<SBOMInventoryPackage[]>([]);
  const [currentChild, setCurrentChild] = useState<SBOMInventoryPackage[]>([]);
  const [relationshipsLoading, setRelationshipsLoading] = useState<boolean>(true);

  const dispatch = useAppDispatch();
  const projectId = useAppSelector(selectProjectID);

  const vulnSeverityString =
    issue && VulnSeverity.hasOwnProperty(issue.severity) ? VulnSeverity[issue.severity].toString() : "";
  const vulnSeverityStringSentenceCase =
    vulnSeverityString.charAt(0).toUpperCase() + vulnSeverityString.slice(1).toLowerCase();
  var vuln = issue?.details.value || undefined;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const modificationMenuOpen = Boolean(anchorEl);
  const [ignoreFormOpen, setIgnoreFormOpen] = useState<boolean>(false);
  const [ignoreVuln, setIgnoreVuln] = useState<{
    id: string | undefined;
    comment: string | undefined;
    justification: string | undefined;
  } | null>(null);
  const [desiredSuppression] = useState<{
    id: string | undefined;
    desired: number;
    current: number;
    existingAutoSuppression: boolean;
  } | null>(null);

  // Update Issue History
  let refreshIssueHistoryList = () => {
    if (!project) {
      return;
    }
  };

  useEffect(refreshIssueHistoryList, [client, componentId, issueId, project]);

  // Handle Issue History Table Refresh
  const handleRefreshIssueHistoryTrigger = () => {
    refreshIssueHistoryList();
  };

  // Handle Ignore Issue
  const handleModificationClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleModificationMenuClose = () => {
    setAnchorEl(null);
  };
  const handleIgnore = (id: string | undefined) => {
    openIgnoreForm(issue);
    setAnchorEl(null);
  };
  const openIgnoreForm = (issue: ComponentIssue | undefined) => {
    setIgnoreVuln({ id: issue?.id, comment: "", justification: "" });
    setIgnoreFormOpen(true);
  };

  const closeIgnoreForm = () => {
    setIgnoreFormOpen(false);
  };

  const handleRefreshIssueTrigger = () => {
    refreshIssueData();
  };

  const handleSpecificPackageModalOpen = async (pkgName: string) => {
    await dispatch(searchForPackageByIssuePackageName({ projectId, packageName: pkgName, componentId: componentId }));
    await dispatch(setTargetCategory({ upgradeCategory: "requested" }));
    await dispatch(setTargetOptions({ subDirectory: component?.subdir }));
    await dispatch(setTargetComponent(component));

    dispatch(openSpecificPackageModal());
  };

  useProjectId((projectId: string) => {
    dispatch(setProjectID(projectId));
  });

  // Fetch component and issue information
  const refreshIssueData = () => {
    if (!project) {
      return;
    }

    if (component) {
      client
        .listComponentIssues({
          projectId: project.id,
          componentId: componentId,
          states: [1, 2, 3],
        })
        .then(
          (res) => {
            const specificItem = res.items.find((item) => item.id === issueId);
            if (specificItem) {
              const formattedIssue = {
                ...specificItem,
                createdAt: specificItem.createdAt
                  ? new Date(Number(specificItem.createdAt.seconds) * 1000).toISOString()
                  : undefined,
                updatedAt: specificItem.updatedAt
                  ? new Date(Number(specificItem.updatedAt.seconds) * 1000).toISOString()
                  : undefined,
                dueAt: specificItem.dueAt
                  ? new Date(Number(specificItem.dueAt.seconds) * 1000).toISOString()
                  : undefined,
              };
              setIssue(formattedIssue);
            }
          },
          (err) => {
            console.log(err);
            setConnectivity(false);
          },
        );

      client
        .listComponentIssueStateChanges({
          projectId: project.id,
          componentId: componentId,
          issueId: issueId,
        })
        .then(
          (res) => {
            setIssueStateChanges(res.componentIssueStateChanges);
          },
          (err) => {
            console.log(err);
            setConnectivity(false);
          },
        );
    }
  };

  useEffect(() => {
    if (!project) {
      return;
    }

    if (component === undefined) {
      client.getComponent({ projectId: project.id, id: componentId }).then(
        (res) => {
          if (!res.component) {
            throw new Error("No component returned");
          }
          setComponent(componentFromPB(res.component));
        },
        (err) => {
          console.log(err);
          setConnectivity(false);
        },
      );
    }
  }, [project, client, componentId, issueId, component]);

  useEffect(refreshIssueData, [project, client, componentId, issueId, component]);

  // Look up latest SBOMs from the Issue, and then look up relationship information for this package
  useEffect(() => {
    if (!project) {
      return;
    }

    if (issue) {
      client
        .getComponentTagsOverview({
          projectId: project.id,
          componentId: issue.componentId,
        })
        .then(
          (res) => {
            if (res.defaultSbom) {
              setDefaultSBOM(res.defaultSbom?.sbom?.id);
            } else {
              setDefaultSBOM(null);
            }
          },
          (err) => {
            console.log(err);
            setConnectivity(false);
          },
        );
    }

    if (issue) {
      if (defaultSBOM !== null && defaultSBOM !== undefined && relationshipsLoading) {
        // There is a default SBOM, so we can look up relationships for this Issue
        client.getSBOMInventory({ projectId: project.id, id: defaultSBOM }).then(
          (res) => {
            let matchedPackage = getPackageFromName(issue.packageName, res);
            if (matchedPackage) {
              setCurrentPackage([...currentPackage, matchedPackage]);
              res.relationships.map((rel) => {
                if (rel.relatedId === matchedPackage?.packageId) {
                  let relatedPackage = getPackageFromId(rel.id, res);
                  if (rel.relationshipType === "dependency-of" && relatedPackage) {
                    setCurrentAncestor([...currentAncestor, relatedPackage]);
                  }
                  if (rel.relationshipType === "contains" && relatedPackage) {
                    setCurrentChild([...currentChild, relatedPackage]);
                  }
                }
                return null;
              });
            }
            setRelationshipsLoading(false);
          },
          (err) => {
            console.log(err);
            setConnectivity(false);
          },
        );
      } else if (defaultSBOM === null && relationshipsLoading) {
        // There is no default SBOM, eg. it's a Component of Realtime SBOMs
        // Create dummy SBOMInventoryPackage to populate the relationship table
        let dummyPackage = new SBOMInventoryPackage({
          packageId: "111111111111",
          packageName: issue.packageName,
          packageType: issue.packageType,
        });
        setCurrentPackage([...currentPackage, dummyPackage]);
        setRelationshipsLoading(false);
      }
    }
  }, [
    project,
    client,
    componentId,
    issue,
    defaultSBOM,
    currentAncestor,
    currentChild,
    currentPackage,
    relationshipsLoading,
  ]);

  // Fetch integrations to populate Jira links
  // This assumes that there is only one Jira integration per org
  useEffect(() => {
    const fetch = async () => {
      try {
        const integrations = (await client.listIntegrations({})).integrations;
        for (const i of integrations) {
          switch (i.type) {
            case IntegrationType.JIRA:
              setJiraOrgHostname(i.account);
          }
        }
      } catch (err) {
        console.log(err);
        setConnectivity(false);
      }
    };

    fetch();
  }, [client]);

  const fixState: Record<VulnFixState, string> = {
    0: "Unspecified",
    1: "Not fixed yet",
    2: "Not fixed yet", // This is really "Maintainer won't fix" but this makes no sense to users
    3: "Fixed, upgrade",
  };

  const stateNames: Record<ComponentIssueState, string> = {
    [ComponentIssueState.UNSPECIFIED]: "Unknown", // This should never happen
    [ComponentIssueState.IGNORED]: "Ignored",
    [ComponentIssueState.OPEN]: "Open",
    [ComponentIssueState.RESOLVED]: "Resolved",
  };

  function showFixState(state: VulnFixState | undefined, fixVersions?: Array<string>, openPR: boolean = false) {
    if (state) {
      var message = fixState[state];
      if (fixVersions?.length) {
        return message + (openPR ? " to " + fixVersions.toString() + " with Autofix" : " to " + fixVersions.toString());
      }
      return message;
    } else {
      return "Fix state not specified";
    }
  }

  useEffect(() => {
    if (!issue) {
      document.title = issueId + " / Issues";
    } else {
      document.title = issue.packageName + " / Issues";
    }
  }, [issueId, issue]);

  return (
    <BodyWrapperProjectScoped>
      {!connectivity && <GlobalError message="Error communicating with backend" />}

      <Typography variant="h4" sx={{ display: "inline-block" }}>
        Components /{" "}
        <Link component={NavLink} to={"/components/" + component?.id + "/vulnerabilities"}>
          {component?.displayName}
        </Link>{" "}
        / #{issueId}{" "}
      </Typography>
      <Button
        id="actions-button"
        aria-controls={modificationMenuOpen ? "issue-modification-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={modificationMenuOpen ? "true" : undefined}
        onClick={handleModificationClick}
        sx={{ minWidth: "auto", float: "right", padding: "10px 10px 10px 10px" }}
      >
        <SettingsIcon sx={{ verticalAlign: "middle", fontSize: "24px", color: "#666" }}></SettingsIcon>
        <Box
          sx={{ display: "inline-block", verticalAlign: "middle", fontSize: "14px", marginLeft: "5px", color: "#666" }}
        >
          Actions
        </Box>
      </Button>

      {/* Vuln Ignore Modal */}
      <IssueIgnoreModal
        ignoreFormOpen={ignoreFormOpen}
        setIgnoreVuln={setIgnoreVuln}
        desiredSuppression={desiredSuppression}
        issue={issue}
        ignoreVuln={ignoreVuln}
        closeIgnoreForm={closeIgnoreForm}
        project={project}
        componentId={componentId}
        refreshAfterSave={handleRefreshIssueTrigger}
      />
      <IssueModificationMenu
        anchorEl={anchorEl}
        open={modificationMenuOpen}
        onClose={handleModificationMenuClose}
        handleIgnore={handleIgnore}
        issue={issue}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      />

      <DialogTriggerPackageAnalysis />

      <Box sx={{ width: "100%" }}>
        <Box sx={{ marginTop: "40px", fontSize: "14px" }}>
          <OverviewBox title="Issue Details">
            <Grid container spacing={2}>
              <Grid item lg={3} md={4} xs={6}>
                <Typography variant="h6" sx={{ fontSize: "14px", marginTop: "10px" }}>
                  Issue State
                </Typography>
                <Box sx={{ display: "inline-block", fontSize: "14px" }}>
                  {issue ? stateNames[issue?.state] : "Loading"}
                </Box>
                {issue?.state === ComponentIssueState.OPEN && (
                  <>
                    <Typography variant="h6" sx={{ fontSize: "14px", marginTop: "10px" }}>
                      Action required
                    </Typography>
                    <Box sx={{ display: "inline-block", fontSize: "14px" }}>
                      {issue.state === ComponentIssueState.OPEN && issue.packageType !== "npm" && (
                        <Box
                          sx={{
                            display: "inline-block",
                            fontSize: "13px",
                          }}
                        >
                          {showFixState(vuln?.fixState, vuln?.fixVersions)}
                        </Box>
                      )}
                      {issue.state === ComponentIssueState.OPEN &&
                        issue.packageType === "npm" &&
                        vuln?.fixState === VulnFixState.FIXED && (
                          <FeatureFlagGate
                            flag="code-analysis"
                            fallback={
                              <Box
                                sx={{
                                  display: "inline-block",
                                  fontSize: "13px",
                                }}
                              >
                                {showFixState(vuln?.fixState, vuln?.fixVersions)}
                              </Box>
                            }
                          >
                            <EdgeBitPrimaryButton
                              onClick={() => handleSpecificPackageModalOpen(issue?.packageName)}
                              sx={{ fontSize: "13px", padding: "2px 10px", marginTop: "5px" }}
                            >
                              {showFixState(vuln?.fixState, vuln?.fixVersions, true)}
                            </EdgeBitPrimaryButton>
                          </FeatureFlagGate>
                        )}
                    </Box>
                  </>
                )}
                <Typography variant="h6" sx={{ fontSize: "14px", marginTop: "10px" }}>
                  Package Name{" "}
                </Typography>
                <Typography component={"div"} variant="body1" sx={{ fontSize: "14px" }}>
                  <Box sx={{ display: "inline-block", wordWrap: "break-word" }}>
                    {issue ? issue?.packageName : "Loading"}
                  </Box>
                </Typography>
                <Typography variant="h6" sx={{ fontSize: "14px", marginTop: "10px" }}>
                  Package Type{" "}
                </Typography>
                {issue ? <PackageType type={issue?.packageType} name={true} /> : "Loading"}
              </Grid>
              <Grid item lg={3} md={3} xs={6}>
                <Typography variant="h6" sx={{ fontSize: "14px", marginTop: "10px" }}>
                  Issue severity
                </Typography>
                {issue ? (
                  <Box>
                    <VulnChip preset={VulnSeverity[issue.severity]} innerText={vulnSeverityStringSentenceCase} />
                  </Box>
                ) : (
                  "Loading"
                )}
                <Typography variant="h6" sx={{ fontSize: "14px", marginTop: "10px" }}>
                  CVE
                </Typography>
                <Box>
                  {issue ? (
                    <Link href={issue?.details.value?.references[0].url}>{issue?.details.value?.id}</Link>
                  ) : (
                    "Loading"
                  )}
                </Box>
                {issue && issue.state === ComponentIssueState.OPEN && issue?.dueAt !== undefined && (
                  <>
                    <Typography variant="h6" sx={{ fontSize: "14px", marginTop: "10px" }}>
                      Due Date
                    </Typography>
                    <Box>
                      <RelativeTimestamp timestamp={issue?.dueAt} />
                    </Box>
                    <FormattedTimestamp timestamp={issue?.dueAt} />
                  </>
                )}
                {issue && issue.state !== ComponentIssueState.OPEN && (
                  <>
                    <Typography variant="h6" sx={{ fontSize: "14px", marginTop: "10px" }}>
                      Fixed On Date
                    </Typography>
                    <FormattedTimestamp timestamp={issue?.updatedAt} />
                  </>
                )}
                {!issue && (
                  <>
                    <Typography variant="h6" sx={{ fontSize: "14px", marginTop: "10px" }}>
                      Due Date
                    </Typography>
                    Loading
                  </>
                )}
                {issue && jiraOrgHostname && getJiraIssueID(issue) !== "" && (
                  <>
                    <Typography variant="h6" sx={{ fontSize: "14px", marginTop: "10px" }}>
                      Jira Issue
                    </Typography>
                    <Box sx={{ fontSize: "14px" }}>
                      <Link href={getJiraIssueLink(jiraOrgHostname, getJiraIssueID(issue))}>
                        {getJiraIssueID(issue)}
                      </Link>
                    </Box>
                  </>
                )}
              </Grid>
              <Grid item lg={6} md={5} xs={6}>
                <Typography variant="h6" sx={{ fontSize: "14px", marginTop: "10px" }}>
                  Relationships
                </Typography>
                <RelatedPackage packages={currentAncestor} title="Parent" level={0} loading={relationshipsLoading} />
                <RelatedPackage
                  packages={currentPackage}
                  title="This Package"
                  level={1}
                  loading={relationshipsLoading}
                />
                <RelatedPackage packages={currentChild} title="Child" level={2} loading={relationshipsLoading} />
                <Typography variant="h6" sx={{ fontSize: "14px", marginTop: "10px" }}>
                  Evidence
                </Typography>
                {currentPackage.map((pkg) => (
                  <Box key={pkg.packageId}>
                    {pkg.evidence?.evidentBy?.map((location) => (
                      <Box key={location}>
                        Evident By:{" "}
                        <Box
                          sx={{
                            display: "inline-block",
                            wordWrap: "break-word",
                          }}
                        >
                          {location}
                        </Box>
                      </Box>
                    ))}
                    {pkg.evidence?.evidentBy.length === 0 && (
                      <Box>
                        <Box sx={{ display: "inline", paddingRight: "3px" }}>Evident By:</Box>
                        <Box sx={{ display: "inline", color: "#999" }}>None found in SBOM</Box>
                      </Box>
                    )}
                    {/* Hiding this until this is populated in the API */}
                    {/* {pkg.evidence?.installedBy?.map((location) => (
                                <Box key={location}>
                                  Installed By: <Box sx={{display: 'inline-block', wordWrap: 'break-word'}}>{location}</Box>
                                </Box>
                              ))} */}
                    {/* {(pkg.evidence?.installedBy.length === 0) && (
                                <Box>
                                  <Box sx={{display: 'inline', paddingRight: '3px'}}>Installed By:</Box>
                                  <Box sx={{display: 'inline', color: '#999'}}>None found in SBOM</Box>
                                </Box>
                              )} */}
                  </Box>
                ))}
                {relationshipsLoading && <Box>Loading</Box>}
              </Grid>
            </Grid>
          </OverviewBox>
        </Box>
        <Box>
          <OverviewBox title="Issue Description">
            {issue?.details.value?.description ? (
              <Box
                sx={{
                  display: "inline-block",
                  fontSize: "14px",
                  paddingTop: "10px",
                }}
              >
                {issue?.details.value?.description}
              </Box>
            ) : (
              <Box
                sx={{
                  display: "inline-block",
                  fontSize: "14px",
                  paddingTop: "10px",
                  color: "#999",
                }}
              >
                No description provided
              </Box>
            )}
          </OverviewBox>
        </Box>
        <Typography variant="h6" sx={{ marginBottom: "10px" }}>
          Issue History
        </Typography>
        {issueStateChanges ? (
          <ComponentIssueHistoryTable
            issueStateChanges={issueStateChanges}
            refreshHistory={handleRefreshIssueHistoryTrigger}
          />
        ) : (
          <Box
            sx={{
              display: "flex",
              marginBottom: "20px",
              borderRadius: "4px",
              boxShadow:
                "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
              border: "1px solid #ddd",
              borderBottom: "0px",
            }}
          >
            <Box
              sx={{
                textAlign: "center",
                display: "block",
                justifyContent: "center",
                alignItems: "center",
                width: "400px",
                marginTop: "20px",
                marginLeft: "auto",
                marginRight: "auto",
              }}
            >
              <Typography variant="h6" gutterBottom sx={{ display: "block", width: "400px" }}>
                Loading issue history...
              </Typography>
              <LinearProgress
                sx={{
                  width: "200px",
                  marginTop: "3px",
                  marginRight: "auto",
                  marginBottom: "20px",
                  marginLeft: "auto",
                }}
              />
            </Box>
          </Box>
        )}
      </Box>
    </BodyWrapperProjectScoped>
  );
};
