import { Alert, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { useContext, useEffect, useState } from "react";
import { ProjectContext } from "../App";
import { useClient } from "../api/client";
import { BodyWrapperProjectScoped } from "../components/BodyWrapperProjectScoped";
import { ComponentSettings, ComponentSettingsData } from "../components/ComponentSettings";
import { EdgeBitPrimaryButton } from "../components/EdgeBitPrimaryButton";
import { EdgeBitPublicAPIService } from "../pb/edgebit/platform/v1alpha/platform_connectweb";
import { ComponentType } from "../pb/edgebit/platform/v1alpha/platform_pb";
import { useNavigate } from "react-router-dom";
import { Code } from "@bufbuild/connect";

import { useAppDispatch, useAppSelector } from "../app/hooks";
import useProjectId from "../hooks/useProjectId";
import { fetchRepoList, selectRepos } from "../features/repoListSlice";
import { sourceRepoToSourceRepositoryPB } from "./helpers/components";

export const ComponentCreate = () => {
  // API client
  const client = useClient(EdgeBitPublicAPIService);
  const project = useContext(ProjectContext);
  const repos = useAppSelector(selectRepos);
  const dispatch = useAppDispatch();

  useProjectId((projectId: string) => {
    dispatch(fetchRepoList({ projectId: projectId }));
  });

  const [formInputs, setFormInputs] = useState<ComponentSettingsData>({
    autoUpdateName: true,
    availSourceRepositories: repos || [],
  });
  const [formError, setFormError] = useState<string | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    setFormInputs({ ...formInputs, availSourceRepositories: repos || [] });
  }, [repos, formInputs]);

  // Save Form
  const handleComponentSave = () => {
    setFormError(null);

    if (!project) {
      console.log("No project ID set, can't create token");
      return;
    }

    const sourceRepository = sourceRepoToSourceRepositoryPB(
      repos || [],
      formInputs.selectedSourceRepository,
      formInputs.customSourceRepository,
    );

    client
      .createComponent({
        projectId: project.id,
        name: formInputs.name,
        displayName: formInputs.displayName,
        type: ComponentType.SBOM_COLLECTION,
        sourceRepository,
        subdir: formInputs.subdir,
      })
      .then(
        (res) => {
          console.log(res);
          navigate(`/components/${res.component?.id}`);
        },
        (err) => {
          if (err.code === Code.InvalidArgument) {
            setFormError(err.rawMessage);
          } else {
            setFormError("Unknown error");
          }
        },
      );
  };

  useEffect(() => {
    document.title = "Create Component";
  });

  return (
    <BodyWrapperProjectScoped>
      <Typography variant="h4" gutterBottom sx={{ marginBottom: "30px" }}>
        Create Component
      </Typography>
      <ComponentSettings data={formInputs} onChange={setFormInputs} />
      <Box
        sx={{
          borderTop: "1px solid #ddd",
          marginTop: "10px",
          paddingTop: "10px",
        }}
      >
        {formError && (
          <Alert style={{ marginTop: 11 }} severity="error">
            {formError}
          </Alert>
        )}
        <EdgeBitPrimaryButton
          type="submit"
          variant="outlined"
          size="medium"
          onClick={handleComponentSave}
          sx={{ marginTop: "20px", marginBottom: "0px" }}
        >
          Create Component
        </EdgeBitPrimaryButton>
      </Box>
    </BodyWrapperProjectScoped>
  );
};
